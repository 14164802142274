import API from '../lib/api'

export default class ServicoParceiroService {

    constructor() {
        this.baseURL = 'servicoparceiro'
        this.inclui = this.inclui.bind(this)
        this.altera = this.altera.bind(this)
        this.exclui = this.exclui.bind(this)
        this.getById = this.getById.bind(this)
        this.lista = this.lista.bind(this)
    }

    async inclui(servicoParceiro) {

        const resp = await API.post(this.baseURL, servicoParceiro)

        if (resp.status !== 201) {
            throw new Error(`codigo: ${resp.status} \n ${resp.statusText}`)
        }

        return resp.data
    }

    async altera(servicoParceiro) {

        const resp = await API.put(this.baseURL, servicoParceiro)

        if (resp.status !== 200) {
            throw new Error(`codigo: ${resp.status} \n ${resp.statusText}`)
        }

        return resp.data
    }

    async exclui(servicoParceiro) {

        const resp = await API.delete(`${this.baseURL}/${servicoParceiro.id}`)

        if (resp.status !== 200) {
            throw new Error(`codigo: ${resp.status} \n ${resp.statusText}`)
        }

        return resp.data
    }

    async getById(id) {

        const resp = await API.get(`${this.baseURL}/${id}`)

        if (resp.status !== 200) {
            throw new Error(`codigo: ${resp.status} \n ${resp.statusText}`)
        }

        return resp.data
    }

    async lista() {

        const resp = await API.get(this.baseURL)

        if (resp.status !== 200) {
            throw new Error(`codigo: ${resp.status} \n ${resp.statusText}`)
        }

        return resp.data
    }
}