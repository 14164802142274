import API from '../lib/api'
import { b64toBlob, currentTimeStamp, isObjectEmpty, somenteNumeros } from '../lib/util'


export default class PessoaService {

    constructor() {
        this.baseURL = 'pessoa'
        this.inclui = this.inclui.bind(this)
        this.altera = this.altera.bind(this)
        this.getById = this.getById.bind(this)
        this.lista = this.lista.bind(this)
        this.uploadFoto = this.uploadFoto.bind(this)
        this.uploadFotoB64 = this.uploadFotoB64.bind(this)
    }

    async inclui(pessoa) {
        normaliza(pessoa)
        const resp = await API.post(this.baseURL, pessoa)
        return resp.data
    }

    async altera(pessoa) {
        const resp = await API.put(this.baseURL, pessoa)
        return resp.data
    }

    async getById(id) {
        const resp = await API.get(`${this.baseURL}/${id}`)
        return resp.data
    }

    async lista(pasta, filtro, { page = 0, size = 30 }) {
        const resp = await API.post(`${this.baseURL}/lista?pasta=${isObjectEmpty(pasta) ? '' : pasta.descricao}&page=${page}&size=${size}`, filtro)
        return resp.data
    }

    async uploadFoto(idPessoa, fotoFile) {

        const formData = new FormData()

        formData.append('id', idPessoa)
        formData.append("file", fotoFile, fotoFile.name)

        const resp = await API.post(`${this.baseURL}/foto`, formData)

        return resp.data
    }

    async uploadFotoB64(idPessoa, base64) {

        // Split the base64 string in data and contentType
        let block = base64.split(";")

        // Get the content type of the image
        let contentType = block[0].split(":")[1]

        // Get the extension of the image
        let extension = contentType.split('/')[1]

        // get the real base64 content of the file
        let data = block[1].split(",")[1]

        // Convert it to a blob to upload
        let blob = b64toBlob(data, contentType);

        let timeStamp = currentTimeStamp()

        const formData = new FormData()

        formData.append('id', idPessoa)
        formData.append("file", blob, `${timeStamp}.${extension}`)

        const resp = await API.post(`${this.baseURL}/foto`, formData)

        return resp.data
    }
}

function normaliza(pessoa) {
    pessoa.documentos.cpf = somenteNumeros(pessoa.documentos.cpf)
    pessoa.documentos.rg = somenteNumeros(pessoa.documentos.rg)
    pessoa.telefones.forEach(tel => tel.numero = somenteNumeros(tel.numero))
}