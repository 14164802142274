import API from '../lib/api'

export default class UsuarioService {

    constructor() {
        this.baseURL = 'usuario'
    }

    async inclui(usuario) {
        const resp = await API.post(this.baseURL, usuario)
        return resp.data
    }

    async altera(usuario) {
        const resp = await API.put(this.baseURL, usuario)
        return resp.data
    }

    async alterarStatus(idUsuario, status) {

        const formData = new FormData()

        formData.append('id', idUsuario)
        formData.append("status", status)

        API.post(`${this.baseURL}/alterarstatus`, formData)
    }

    async lista() {
        const resp = await API.get(`${this.baseURL}/lista`)
        return resp.data
    }

    async getById(id) {
        const resp = await API.get(`${this.baseURL}/${id}`)
        return resp.data
    }

    async addPermissao(idUsuario, nomePermissao) {

        const formData = new FormData()
        formData.append('id', idUsuario)
        formData.append("permissao", nomePermissao)

        const resp = await API.post(`${this.baseURL}/addpermissao`, formData)
        return resp.data
    }

    async removePermissao(idUsuario, nomePermissao) {

        const formData = new FormData()
        formData.append('id', idUsuario)
        formData.append("permissao", nomePermissao)

        const resp = await API.post(`${this.baseURL}/removepermissao`, formData)
        return resp.data
    }
}