import React from 'react'
import { withRouter } from 'react-router-dom'
import { Container, Badge } from 'reactstrap'
import PessoaService from '../../../service/PessoaService'
import { FaWhatsapp } from 'react-icons/fa'
import { formatter } from '../../../lib/util'

import "./style.css"

class ViewPessoa extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pessoa: {
                endereco: {},
                estadoCivil: {},
                sexo: {},
                etnia: {},
                telefones: [],
                quantitativoFamiliar: {},
                informacoes: {
                    profissional: {},
                    grauDeInstrucao: {}
                },
                documentos: {},
                listaPastaPessoa: []
            }
        }
        this.servicePessoa = new PessoaService()
    }

    async componentDidMount() {
        const encodedId = this.props.match.params.id
        if (encodedId) {
            try {
                const id = window.atob(encodedId)
                let pessoa = await this.servicePessoa.getById(id)
                this.setState({ pessoa })
            } catch (error) {
                alert(error.response.data.error)
                this.props.history.goBack()
            }
        }
    }

    render() {

        let quantitativoFamiliar = this.state.pessoa.quantitativoFamiliar

        let qtdTotal = 0
        qtdTotal += quantitativoFamiliar.crianca0a6 ? parseInt(quantitativoFamiliar.crianca0a6) : 0
        qtdTotal += quantitativoFamiliar.crianca7a12 ? parseInt(quantitativoFamiliar.crianca7a12) : 0
        qtdTotal += quantitativoFamiliar.adolescente13a17 ? parseInt(quantitativoFamiliar.adolescente13a17) : 0
        qtdTotal += quantitativoFamiliar.adulto ? parseInt(quantitativoFamiliar.adulto) : 0
        qtdTotal += quantitativoFamiliar.idoso ? parseInt(quantitativoFamiliar.idoso) : 0


        const length = this.state.pessoa.telefones.length
        const telefones = this.state.pessoa.telefones.map(
            (tel, index) =>
            (
                <div style={{ display: 'inline' }}>
                    {formatter.telefone(tel.ddd, tel.numero)}
                    {' '}
                    {tel.whatsapp && <FaWhatsapp style={{ marginBottom: '5px' }} color="#2DB843" />}
                    {(length > 0 && index !== (length - 1)) && ', '}
                </div>
            )
        )

        const pastas = this.state.pessoa.listaPastaPessoa.map(
            (pp, index) =>
            (
                <div key={pp.id} style={{ display: 'inline' }}>
                    <Badge color={index % 2 === 0 ? 'primary' : 'secondary'} >
                        {pp.pasta.descricao}
                    </Badge >
                    {' '}
                </div>
            )
        )

        return (
            <Container style={{ marginTop: '30px' }}>
                <div id="conteudo" className="item">
                    {
                        this.state.pessoa.fotoPessoa &&
                        <img
                            style={{
                                float: 'right',
                                marginTop: '10px',
                                width: '11em',
                            }}
                            src={this.state.pessoa.fotoPessoa.link} alt="foto"
                        />
                    }
                    <h2 style={{ marginBottom: '20px' }}>Identificação:</h2>
                    <p><span>Nome: </span>{this.state.pessoa.nome}</p>
                    <p>
                        <span>RG: </span> {this.state.pessoa.documentos.rg}
                        <span className="span-item">CPF: </span>{formatter.cpf(this.state.pessoa.documentos.cpf)}
                        <span className="span-item">Data Nascimento: </span>{formatter.data(this.state.pessoa.dataNascimento)}
                    </p>
                    <p>
                        <span >Estado Civil: </span>{this.state.pessoa.estadoCivil && this.state.pessoa.estadoCivil.descricao}
                        <span className="span-item">Sexo Biológico: </span>{this.state.pessoa.sexo && this.state.pessoa.sexo.descricao}
                        <span className="span-item">Etnia: </span>{this.state.pessoa.etnia && this.state.pessoa.etnia.descricao}
                    </p>
                    <h2 style={{ marginTop: '30px', marginBottom: '20px' }}>Filiação:</h2>
                    <p><span>Pai: </span>{this.state.pessoa.nomePai}</p>
                    <p><span>Mãe: </span>{this.state.pessoa.nomeMae}</p>
                    <p style={{ marginTop: '30px' }}>
                        <span>Endereço: </span>{this.state.pessoa.endereco && this.state.pessoa.endereco.logradouro}
                        <span className="span-item">Nº: </span>{this.state.pessoa.endereco && this.state.pessoa.endereco.numero}
                        <span className="span-item">Compl: </span>{this.state.pessoa.endereco && this.state.pessoa.endereco.complemento}
                    </p>
                    <p>
                        <span>Bairro: </span>{this.state.pessoa.endereco && this.state.pessoa.endereco.bairro}
                        <span className="span-item">Referência: </span>{this.state.pessoa.endereco && this.state.pessoa.endereco.pontoReferencia}
                        <span className="span-item">CEP: </span>{this.state.pessoa.endereco && formatter.cep(this.state.pessoa.endereco.cep)}
                    </p>
                    <h2 style={{ marginTop: '30px', marginBottom: '20px' }}>Quantitativo Familiar:</h2>
                    <p>
                        <span>Crianças </span>
                        <span>[0 a 6]: </span>{this.state.pessoa.quantitativoFamiliar.crianca0a6}
                        <span className="span-item">[7 a 12]: </span>{this.state.pessoa.quantitativoFamiliar.crianca7a12}
                        <span className="span-item">Adolescente(s): </span>{this.state.pessoa.quantitativoFamiliar.adolescente13a17}
                        <span className="span-item">Adulto(s): </span>{this.state.pessoa.quantitativoFamiliar.adulto}
                        <span className="span-item">Idoso(s): </span>{this.state.pessoa.quantitativoFamiliar.idoso}
                        <span className="span-item">Total: </span>{qtdTotal}
                    </p>
                    <p style={{ marginTop: '30px' }}><span>Formação Profissional: </span>{this.state.pessoa.informacoes.profissional.formacaoProfissional}</p>
                    <p> <span>Deseja se profissionalizar em: </span>{this.state.pessoa.informacoes.profissional.desejaProfissionalizar}</p>
                    <p>
                        <span>Está Trabalhando?: </span>{
                            this.state.pessoa.informacoes.estaTrabalhando != null
                                ? this.state.pessoa.informacoes.estaTrabalhando ? 'Sim' : 'Não'
                                : ''
                        }
                        <span className="span-item">Carteira assinada?: </span>{
                            this.state.pessoa.informacoes.carteiraAssinada != null
                                ? this.state.pessoa.informacoes.carteiraAssinada ? 'Sim' : 'Não'
                                : ''
                        }
                    </p>
                    <p>
                        <span>Grau de instrução: </span>{this.state.pessoa.informacoes.grauDeInstrucao && this.state.pessoa.informacoes.grauDeInstrucao.descricao}
                        <span className="span-item">Deseja voltar a estudar?: </span>{
                            this.state.pessoa.informacoes.desejaVoltarEstudar != null
                                ? this.state.pessoa.informacoes.desejaVoltarEstudar ? 'Sim' : 'Não'
                                : ''
                        }
                    </p>
                    <p style={{ marginTop: '30px' }}><span>Telefone(s): </span>{telefones}</p>
                    <h2 style={{ marginTop: '30px', marginBottom: '20px' }}>Documento(s) que possui:</h2>
                    <p>
                        <input type="checkbox" checked={this.state.pessoa.documentos.temCertidaoNascimento} />
                        <label style={{ marginLeft: '10px' }}>Certidão de Nascimento</label>
                        <input style={{ marginLeft: '100px' }} type="checkbox" checked={this.state.pessoa.documentos.temPispasep} />
                        <label style={{ marginLeft: '10px' }} >PIS/PASEP</label>
                    </p>
                    <p>
                        <input type="checkbox" checked={this.state.pessoa.documentos.temCtps} />
                        <label style={{ marginLeft: '10px' }}>CTPS</label>
                        <input style={{ marginLeft: '262px' }} type="checkbox" checked={this.state.pessoa.documentos.temCertificadoReservista} />
                        <label style={{ marginLeft: '10px' }}>Certificado de Reservista</label>
                    </p>
                    <p>
                        <input type="checkbox" checked={this.state.pessoa.documentos.temCpf} />
                        <label style={{ marginLeft: '10px' }}>CPF</label>
                        <input style={{ marginLeft: '273px' }} type="checkbox" checked={this.state.pessoa.documentos.temTituloEleitor} />
                        <label style={{ marginLeft: '10px' }}>Titulo de Eleitor</label>
                    </p>
                    <p>
                        <input type="checkbox" checked={this.state.pessoa.documentos.temCnh} />
                        <label style={{ marginLeft: '10px' }}>CNH</label>
                        <input style={{ marginLeft: '265px' }} type="checkbox" checked={this.state.pessoa.documentos.temRg} />
                        <label style={{ marginLeft: '10px' }}>RG</label>
                    </p>
                    <h2 style={{ marginTop: '30px', marginBottom: '20px' }}>Observações:</h2>
                    <p>{this.state.pessoa.observacoes}</p>
                    <p style={{ marginTop: '100px' }}>{pastas}</p>
                </div>
            </Container>
        )
    }
}

export default withRouter(ViewPessoa)