import React from 'react'
import { Col, Row, Container, Label, Input } from 'reactstrap'

export default class CadPessoaFiliacao extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pessoa: props.pessoa
        }
    }

    update = (e) => {
        let pessoa = this.state.pessoa
        pessoa[`${e.target.id}`] = e.target.value
        this.setState({ pessoa })
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <Label for="nomePai">Pai</Label>
                        <Input id="nomePai" className="input" autoComplete="off" value={this.state.pessoa.nomePai} onChange={this.update} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label for="nomeMae">Mãe</Label>
                        <Input id="nomeMae" className="input" autoComplete="off" value={this.state.pessoa.nomeMae} onChange={this.update} />
                    </Col>
                </Row>
            </Container>
        )
    }
}