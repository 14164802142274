import API from '../lib/api'

export default class BackupService {

    constructor() {
        this.baseURL = 'backup'
    }

    async gerarBackup() {
        await API.post(`${this.baseURL}/gerar`)
    }

    async statusBackup() {
        const resp = await API.get(`${this.baseURL}/status`)
        return resp.data
    }

    async downloadBackup(downloadProgressCB) {
        const resp = await API.get(`${this.baseURL}/download`, {
            responseType: 'blob',
            onDownloadProgress: downloadProgressCB
        })
        return resp.data
    }
}