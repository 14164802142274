import React from 'react'
import { withRouter } from 'react-router-dom'
import {
    Container,
    Label,
    Input,
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
    Fade
} from 'reactstrap'
import { FaFolderPlus, FaUserPlus, FaList, FaFolderMinus } from 'react-icons/fa'
import { sleep, isObjectEmpty } from '../../../lib/util'
import ReactLoading from "react-loading"
import FiltroPesquisa from './FiltroPesquisa'
import ListaPessoa from './ListaPessoa'
import PastaService from '../../../service/PastaService'
import PessoaService from '../../../service/PessoaService'

import sessionStore from '../../../lib/sessionStore'

import { GerenciarPessoaContext } from './context/GerenciarPessoaContext'
import { AuthContext } from '../../auth/context/AuthContext'

class GerenciarPessoa extends React.Component {

    static contextType = AuthContext

    constructor(props) {
        super(props)
        this.state = {
            pessoas: [],
            pastas: [],
            filtroPesquisa: {
                filtros: []
            },
            pagination: {},
            paginationConfig: {
                page: 0,
                size: 15
            },
            novaPasta: {},
            pastaSelecionada: {},
            isCadastrandoPasta: false,
            isCarregando: false,
            isCadastrouPasta: false,
            erroCadastroPasta: '',
            alertClass: 'alert alert-primary',
            erroValidacaoPasta: false,
            msgValidacaoPasta: '',
            msg: {
                text: '',
                tipo: '',
                visivel: false
            },
            msgConfirmacao: {
                alertClass: 'alert alert-warning',
                isOpen: false,
                titulo: '',
                text: '',
                confirmHandler: () => { },
                cancelHandler: () => { },
            },
            tituloListaAssinatura: '',
            addPessoaPastaDialogOpts: {
                pasta: {},
                isOpen: false,
                isMsg: false,
                textMsg: '',
                confirmHandler: () => { },
                cancelHandler: () => { },
            },
            roles: {
                isPermiteCadastraPessoa: false,
                isPermiteAlteraPessoa: false,
                isPermiteCadastraPasta: false,
                isPermiteExcluirPasta: false,
                isPermiteListaPasta: false
            }
        }
        this.pastaService = new PastaService()
        this.possoaService = new PessoaService()
    }

    async componentDidMount() {
        this.verificaPermissoes(this.init)
    }

    init = async () => {
        this.carregaPastas()
        this.carregaPeginationConfigFromSession()
    }

    carregaPastas = async () => {
        if (!this.state.roles.isPermiteListaPasta) {
            return
        }
        try {
            const pastas = await this.pastaService.pastas()
            const pastaSession = sessionStore.getPasta()

            this.setState({
                pastas
            }, () => {
                this.setState({
                    pastaSelecionada: pastaSession ? pastaSession : this.state.pastaSelecionada
                })
            })
        } catch (error) {
            if (error.response.data.status !== 401) {
                alert(error.response ? error.response.data.message : error.message)
            }
        }
    }

    carregaPeginationConfigFromSession = () => {
        const paginationConfigSession = sessionStore.getPaginationConfigPessoa()
        this.setState({
            paginationConfig: paginationConfigSession ? paginationConfigSession : this.state.paginationConfig
        })
    }

    verificaPermissoes = (initCb) => {

        const isPermiteCadastraPessoa = this.hasPermissao('CADASTRAR_PESSOA')
        const isPermiteAlteraPessoa = this.hasPermissao('ALTERAR_PESSOA')
        const isPermiteCadastraPasta = this.hasPermissao('CADASTRAR_PASTA')
        const isPermiteExcluirPasta = this.hasPermissao('EXCLUIR_PASTA')
        const isPermiteListaPasta = this.hasPermissao('LISTAR_PASTA')

        this.setState({
            roles: {
                isPermiteCadastraPessoa,
                isPermiteAlteraPessoa,
                isPermiteCadastraPasta,
                isPermiteListaPasta,
                isPermiteExcluirPasta
            }
        }, initCb)
    }

    hasPermissao = (roleName) => {
        const roles = this.context.data.roles
        const isPermitido = roles
            .findIndex(role => role === roleName) > -1
        return isPermitido
    }

    setFiltroPesquisa = async (filtroPesquisa) => {
        this.setState({ filtroPesquisa }, this.pesquisar)
    }

    setsessionParams = async () => {
        sessionStore.setFiltroPesquisa(this.state.filtroPesquisa)
        sessionStore.setPasta(this.state.pastaSelecionada)
    }

    pesquisar = async (resetPagination) => {

        this.limpaMsg()

        if (resetPagination) {
            sessionStore.removePaginationConfigPessoa()
            this.setState(
                {
                    paginationConfig: {
                        page: 0,
                        size: 15
                    }
                },
                this.pesquisar
            )
            return
        }

        try {

            if (isObjectEmpty(this.state.pastaSelecionada)
                && this.state.filtroPesquisa.filtros.length === 0) {
                this.setState({ pessoas: [] })
                return
            }

            if (!this.isFiltrosValidos()) {
                throw new Error('Preencha os filtros antes de efetuar a pesquisa')
            }

            const result = await this.possoaService.lista(
                this.state.pastaSelecionada,
                this.state.filtroPesquisa,
                this.state.paginationConfig
            )

            const pessoas = result.content

            let msg = {
                visivel: false
            }

            if (pessoas.length < 1) {
                msg = {
                    text: 'A pesquisa não obteve resultados',
                    tipo: 'alert alert-info',
                    visivel: true
                }
            }

            this.setState({
                pessoas,
                isCarregandoPesquisa: false,
                pagination: {
                    total: result.totalPages,
                    number: result.number,
                    first: result.first,
                    last: result.last
                },
                msg
            }, this.setsessionParams)

        } catch (error) {
            this.setState({
                pessoas: [],
                msg: {
                    text: error.response ? error.response.data.message : error.message,
                    tipo: 'danger',
                    visivel: true
                }
            })
        }
    }

    isFiltrosValidos = () => {

        const invalids = this.state.filtroPesquisa.filtros.filter(f => !f.field || !f.comparator || !f.value)

        if (this.state.filtroPesquisa.filtros.length > 0 && invalids.length > 0) {
            return false
        }

        return true
    }

    paginationHandler = (page) => {

        const paginationConfig = this.state.paginationConfig
        paginationConfig.page = page

        sessionStore.setPaginationConfigPessoa(paginationConfig)

        this.setState({ paginationConfig }, this.pesquisar)
    }

    whenFilterRemoved = () => {
        if (this.isFiltrosValidos()) {
            this.pesquisar(true)
        }
    }

    limpaMsg = () => {
        this.setState({
            msg: {
                text: '',
                tipo: '',
                visivel: false
            }
        })
    }

    redirect = (to) => {
        this.props.history.push(to)
    }

    update = (e) => {
        if (e.target.id === 'pastaSelecionada') {

            const idPasta = e.target.value
            const pastaSelecionada = this.state.pastas.filter(p => String(p.id) === idPasta)[0] || {}
            this.setState(
                {
                    pastaSelecionada,
                },
                () => this.pesquisar(true)
            )

        }
        else if (e.target.id === 'pastaAddPessoa') {

            const idPasta = e.target.value
            const pastaSelecionada = this.state.pastas.filter(p => String(p.id) === idPasta)[0] || {}

            const addPessoaPastaDialogOpts = this.state.addPessoaPastaDialogOpts
            addPessoaPastaDialogOpts.pasta = pastaSelecionada

            this.setState({ addPessoaPastaDialogOpts })

        }
        else if (e.target.id === 'pasta') {
            this.setState({
                novaPasta: {
                    descricao: e.target.value
                }
            })
        }
    }

    cadastrarPasta = async () => {
        try {

            if (!this.state.novaPasta.descricao) {
                this.setState({
                    erroValidacaoPasta: true,
                    msgValidacaoPasta: 'Informe o nome da pasta antes de confirmar'
                })
                return
            }

            this.setState({ erroValidacaoPasta: false, isCarregando: true })

            await sleep(1000, this.pastaService.inclui, this.state.novaPasta)

            const pastas = await this.pastaService.pastas()

            this.setState({
                pastas,
                novaPasta: {},
                isCarregando: false,
                isCadastrouPasta: true,
                alertClass: 'alert alert-success'
            })

        } catch (error) {
            this.setState({
                isCarregando: false,
                isCadastrouPasta: false,
                erroCadastroPasta: error.response ? error.response.data.message : error.message,
                alertClass: 'alert alert-danger'
            })
        }
    }

    encaminharEdicao = (pessoa) => {
        this.setState({
            msgConfirmacao: {
                alertClass: 'alert alert-warning',
                isOpen: true,
                titulo: 'Editar Cadastro',
                text: `Deseja editar o cadastro do(a) ${pessoa.nome}`,
                confirmHandler: async () => {
                    const encodedId = window.btoa(pessoa.id)
                    this.props.history.push(`/pessoa/editar/${encodedId}`)
                },
                cancelHandler: () => {
                    this.setState({
                        msgConfirmacao: {
                            isOpen: false
                        }
                    })
                }
            }
        })
    }

    gerarListaAssinatura = () => {

        if (this.state.pessoas.length < 1) {
            return
        }

        this.setState({
            msgConfirmacao: {
                alertClass: 'alert alert-info',
                isOpen: true,
                titulo: 'Gerar Lista de Assinatura',
                text: (
                    <div>
                        <Label for="titulo-lista">Título Lista</Label>
                        <Input
                            id="titulo-lista"
                            placeholder={this.state.pastaSelecionada.descricao}
                            onChange={
                                (e) => {
                                    this.setState({ tituloListaAssinatura: e.target.value })
                                }
                            }
                        />
                    </div>
                ),
                confirmHandler: async () => {
                    const props = {
                        pastaSelecionada: this.state.pastaSelecionada,
                        filtroPesquisa: this.state.filtroPesquisa,
                        pagination: {
                            total: this.state.pagination.total,
                            size: this.state.paginationConfig.size
                        },
                        titulo: this.state.tituloListaAssinatura
                    }

                    const propsAsString = JSON.stringify(props)

                    const encodedPropsUrl = window.btoa(propsAsString)

                    this.props.history.push(`/pessoa/lista/${encodedPropsUrl}`)
                },
                cancelHandler: () => {
                    this.setState({
                        msgConfirmacao: {
                            isOpen: false
                        }
                    })
                }
            }
        })

    }

    removePessoaPasta = async (pastaPessoa, pessoa) => {
        this.setState({
            msgConfirmacao: {
                alertClass: 'alert alert-warning',
                isOpen: true,
                titulo: 'Remover Pessoa da pasta',
                text: `Confirmar a remoção do(a) ${pessoa.nome} da pasta ${this.state.pastaSelecionada.descricao}`,
                confirmHandler: async () => {
                    try {

                        await this.pastaService.removePessoaPasta(pastaPessoa)
                        this.pesquisar()

                        this.setState({ msgConfirmacao: { isOpen: false } })

                    } catch (error) {
                        this.setState({
                            msg: {
                                text: error.response ? error.response.data.message : error.message,
                                tipo: 'danger',
                                visivel: true
                            }
                        })
                    }
                },
                cancelHandler: () => {
                    this.setState({
                        msgConfirmacao: {
                            isOpen: false
                        }
                    })
                }
            }
        })
    }

    deletaPasta = () => {
        this.setState({
            msgConfirmacao: {
                alertClass: 'alert alert-warning',
                isOpen: true,
                titulo: 'Excluir pasta',
                text: `Confirmar a exclusão da pasta ${this.state.pastaSelecionada.descricao}?`,
                confirmHandler: async () => {
                    try {

                        await this.pastaService.inativa(this.state.pastaSelecionada)

                        const pastas = this.state.pastas.filter(p => p.id !== this.state.pastaSelecionada.id)

                        this.setState({
                            msgConfirmacao: { isOpen: false },
                            pastas,
                            pastaSelecionada: {},
                            pessoas: [],
                            msg: {
                                text: 'Pasta excluída com sucesso',
                                tipo: 'success',
                                visivel: true
                            },
                        }, this.setsessionParams)

                    } catch (error) {
                        this.setState({
                            msg: {
                                text: error.response ? error.response.data.message : error.message,
                                tipo: 'danger',
                                visivel: true
                            }
                        })
                    }
                },
                cancelHandler: () => {
                    this.setState({
                        msgConfirmacao: {
                            isOpen: false
                        }
                    })
                }
            }
        })
    }

    adicionaPessoaPastaHandler = (pessoa) => {

        this.setState({
            addPessoaPastaDialogOpts: {
                isOpen: true,
                isMsg: false,
                confirmHandler: async () => {
                    try {

                        const hasPasta = pessoa.listaPastaPessoa
                            .filter(pp => pp.pasta.id === this.state.addPessoaPastaDialogOpts.pasta.id).length > 0

                        if (hasPasta) {

                            const addPessoaPastaDialogOpts = this.state.addPessoaPastaDialogOpts
                            addPessoaPastaDialogOpts.isMsg = true
                            addPessoaPastaDialogOpts.textMsg = `${pessoa.nome} já está incluso(a) na pasta ${this.state.addPessoaPastaDialogOpts.pasta.descricao}`

                            this.setState({ addPessoaPastaDialogOpts })

                            return
                        }

                        const novaPasta = {
                            id: 0,
                            pasta: this.state.addPessoaPastaDialogOpts.pasta,
                            ativa: true
                        }

                        pessoa.listaPastaPessoa.push(novaPasta)

                        const pastaPessoaCadastrada = await this.pastaService.addPessoaPasta(
                            novaPasta,
                            pessoa
                        )

                        novaPasta.id = pastaPessoaCadastrada.id

                        this.setState({
                            addPessoaPastaDialogOpts: {
                                isOpen: false,
                                pasta: {}
                            },
                        })

                    } catch (error) {

                        this.setState({
                            addPessoaPastaDialogOpts: {
                                pasta: {},
                                isOpen: false
                            },
                            msg: {
                                text: error.response ? error.response.data.message : error.message,
                                tipo: 'danger',
                                visivel: true
                            }
                        })
                    }
                },
                cancelHandler: () => {
                    this.setState({
                        addPessoaPastaDialogOpts: {
                            pasta: {},
                            isOpen: false
                        }
                    })
                }
            }
        })
    }

    closeModalCadastroPasta = () => {
        this.setState({
            isCadastrandoPasta: false,
            isCadastrouPasta: false,
            novaPasta: {},
            alertClass: 'alert alert-primary',
            erroCadastroPasta: ''
        })
    }

    render() {

        const pastasComp = this.state.pastas.map(p => <option key={p.id} value={p.id}>{p.descricao}</option>)
        pastasComp.splice(0, 0,
            <option key={-1} value={-1}>
                {
                    this.state.roles.isPermiteListaPasta
                        ? 'Selecione uma Pasta'
                        : 'Listagem de pastas não permitida'
                }
            </option>
        )

        return (
            <GerenciarPessoaContext.Provider
                value={{
                    pesquisar: this.pesquisar,
                    pessoaService: new PessoaService(),
                    isPermiteAlteraPessoa: this.state.roles.isPermiteAlteraPessoa
                }}
            >
                <Container style={{ marginTop: '10px' }}>
                    <Row>
                        <Col xs="6">
                            <Container style={{ paddingTop: '30px' }}>
                                <Row>
                                    <Col >
                                        <div style={{ display: 'flex' }}>
                                            <Input
                                                disabled={!this.state.roles.isPermiteListaPasta}
                                                type="select"
                                                id="pastaSelecionada"
                                                value={this.state.pastaSelecionada.id}
                                                onChange={this.update}
                                            >
                                                {pastasComp}
                                            </Input>
                                            <Button
                                                disabled={!this.state.roles.isPermiteCadastraPasta}
                                                size="sm"
                                                style={{ marginLeft: '15px', height: '80%', backgroundColor: '#007BFF' }}
                                                onClick={() => this.setState({ isCadastrandoPasta: true })}
                                            >
                                                <FaFolderPlus style={{ marginBottom: '3px' }} />
                                            </Button>
                                            <Fade>
                                                <Button
                                                    size="sm"
                                                    style={{ marginLeft: '15px', height: '80%', backgroundColor: '#E2555B' }}
                                                    onClick={this.deletaPasta}
                                                    disabled={!this.state.roles.isPermiteExcluirPasta || isObjectEmpty(this.state.pastaSelecionada)}
                                                >
                                                    <FaFolderMinus style={{ marginBottom: '3px' }} />
                                                </Button>
                                            </Fade>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col>
                            <Container style={{ paddingTop: '30px' }}>
                                <Row>
                                    <Col>
                                        {
                                            !isObjectEmpty(this.state.pastaSelecionada) &&
                                            <Fade>
                                                <Button size="sm" style={{ float: 'right' }} onClick={this.gerarListaAssinatura}>
                                                    {'Gerar lista de assinatura '}
                                                    <FaList style={{ marginBottom: '3px', marginLeft: '5px' }} />
                                                </Button>
                                            </Fade>
                                        }
                                    </Col>
                                    <Col >
                                        <Button
                                            color="success"
                                            size="sm"
                                            style={{ float: 'right' }}
                                            onClick={this.redirect.bind(this, '/pessoa/cadastro')}
                                            disabled={!this.state.roles.isPermiteCadastraPessoa}
                                        >
                                            {'Nova Pessoa '}
                                            <FaUserPlus style={{ marginBottom: '3px', marginLeft: '5px' }} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>

                    <FiltroPesquisa
                        pesquisaHandler={this.pesquisar}
                        whenFilterRemovedHandler={this.whenFilterRemoved}
                        filtroPesquisa={this.state.filtroPesquisa}
                        setFiltroPesquisaParent={this.setFiltroPesquisa}
                    />

                    <Alert
                        style={{ marginTop: '50px' }}
                        color={this.state.msg.tipo}
                        isOpen={this.state.msg.visivel}
                        children={this.state.msg.text}
                        toggle={() => this.limpaMsg()}
                    />

                    <ListaPessoa
                        pessoas={this.state.pessoas}
                        pagination={this.state.pagination}
                        paginationHandler={this.paginationHandler}
                        pastaSelecionada={this.state.pastaSelecionada}
                        encaminharEdicaoHandler={this.encaminharEdicao}
                        removePessoaPastaHandler={this.removePessoaPasta}
                        adicionaPessoaPastaHandler={this.adicionaPessoaPastaHandler}
                    />

                    {/* modal cadastro pasta */}
                    <Modal
                        isOpen={this.state.isCadastrandoPasta}
                        backdrop={true}
                    >
                        <ModalHeader className={this.state.alertClass}>
                            <div>
                                <h4>Cadastrar nova Pasta</h4>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Alert color='danger' isOpen={this.state.erroValidacaoPasta} toggle={() => this.setState({ erroValidacaoPasta: false })}>
                                {this.state.msgValidacaoPasta}
                            </Alert>
                            {
                                this.state.isCadastrouPasta
                                    ? (<b>Pasta Cadastrada com sucesso</b>)
                                    : this.state.erroCadastroPasta
                                        ? this.state.erroCadastroPasta
                                        : (
                                            this.state.isCarregando
                                                ? (<Row>
                                                    <Col xs={12} md={3} className="mr-auto ml-auto">
                                                        <ReactLoading type="spokes" color="#0069D9" />
                                                    </Col>
                                                </Row>)
                                                : (<div>
                                                    <Label>Nome Pasta</Label>
                                                    <Input id="pasta" onChange={this.update} />
                                                </div>)
                                        )
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={this.cadastrarPasta}
                                hidden={this.state.isCadastrouPasta || this.state.isCarregando || this.state.erroCadastroPasta}
                            >
                                Confirmar
                            </Button>
                            <Button color="secondary" onClick={this.closeModalCadastroPasta}>Fechar</Button>
                        </ModalFooter>
                    </Modal>

                    {/* modal confirmacoes */}
                    <Modal
                        isOpen={this.state.msgConfirmacao.isOpen}
                        backdrop={true}
                    >
                        <ModalHeader className={this.state.msgConfirmacao.alertClass}>
                            <div>
                                <h4>{this.state.msgConfirmacao.titulo}</h4>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            {this.state.msgConfirmacao.text}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.state.msgConfirmacao.confirmHandler}>Confirmar</Button>
                            <Button color="secondary" onClick={this.state.msgConfirmacao.cancelHandler}>Fechar</Button>
                        </ModalFooter>
                    </Modal>

                    {/* modal adiciona pasta na pessoa */}
                    <Modal
                        isOpen={this.state.addPessoaPastaDialogOpts.isOpen}
                        backdrop={true}
                        autoFocus="true"
                    >
                        <ModalHeader className="alert alert-primary">
                            <div>
                                <h4>Adicionar Pessoa na Pasta</h4>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <Alert color='danger' isOpen={this.state.addPessoaPastaDialogOpts.isMsg}>
                                {this.state.addPessoaPastaDialogOpts.textMsg}
                            </Alert>
                            <Label for="pastaAddPessoa">Selecione uma pasta</Label>
                            <Input type="select" id="pastaAddPessoa" onChange={this.update}>
                                {pastasComp}
                            </Input>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.state.addPessoaPastaDialogOpts.confirmHandler}>Confirmar</Button>
                            <Button color="secondary" onClick={this.state.addPessoaPastaDialogOpts.cancelHandler}>Fechar</Button>
                        </ModalFooter>
                    </Modal>
                </Container>
            </GerenciarPessoaContext.Provider>
        )
    }
}

export default withRouter(GerenciarPessoa)