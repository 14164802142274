import React from 'react'
import { Container, Row, Col, Button, Alert } from 'reactstrap'
import Webcam from 'react-webcam'
import ReactLoading from 'react-loading'
import { sleep } from '../../../lib/util'

export default class CadPessoaUploadFotoWebCam extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            screenshot: null,
            msgVisible: false,
            msgText: '',
            msgType: '',
            loading: false
        }
        this.webcamRef = React.createRef();
    }

    capture = () => {
        const screenshot = this.webcamRef.current.getScreenshot();
        this.setState({ screenshot });
    }

    enviarFoto = async () => {
        try {
            const { servicePessoa, pessoa } = this.props
            this.setState({ loading: true })
            await sleep(
                100,
                servicePessoa.uploadFotoB64,
                pessoa.id,
                this.state.screenshot
            )
            this.exibeMsg('Foto enviada com sucesso', 'success')
        } catch (error) {
            this.exibeMsg(error.response.data.error, 'danger')
        }
    }

    exibeMsg = (text, type) => {
        this.setState({ msgVisible: true, msgText: text, msgType: type, loading: false })
    }

    render() {
        return (
            <Container>
                {
                    this.state.loading
                        ? <Row>
                            <Col xs={12} md={3} className="mr-auto ml-auto" style={{ marginTop: '20px' }}>
                                <ReactLoading type="spokes" color="#0069D9" />
                            </Col>
                        </Row>
                        :
                        <Alert
                            color={this.state.msgType}
                            isOpen={this.state.msgVisible}
                            toggle={() => this.setState({ msgVisible: false })}
                            style={{ marginTop: '20px' }}
                        >
                            {this.state.msgText}
                        </Alert>
                }
                <Row>
                    <Col style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <Webcam
                            audio={false}
                            width={"100%"}
                            height={"100%"}
                            ref={this.webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={{
                                facingMode: "user",

                            }}
                            mirrored={true}
                            screenshotQuality={1}
                            forceScreenshotSourceSize={true}
                        />
                    </Col>
                    <Col style={{ marginTop: '10px', marginBottom: '10px' }}>
                        {this.state.screenshot ? <img src={this.state.screenshot} alt="Foto captura" width={"100%"} height={"100%"} /> : null}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button color="primary" onClick={this.capture}>Capturar foto</Button>
                    </Col>
                    <Col>
                        {this.state.screenshot && <Button color="primary" onClick={this.enviarFoto} >Enviar foto</Button>}
                    </Col>
                </Row>
            </Container>
        )
    }
}