const localKeyAuth = '@rdp/auth'

const localStore = {

    setAuth(auth) {
        localStorage.setItem(localKeyAuth, JSON.stringify(auth))
    },

    getAuth() {
        const authAsString = localStorage.getItem(localKeyAuth)
        const auth = JSON.parse(authAsString)
        return auth
    },

    clear() {
        localStorage.clear()
    }
}

export default localStore;