import React from 'react'
import ListaArquivoItem from './ListaArquivoItem'

export default class ListaArquivo extends React.Component {

    render() {

        const arquivosComponent = this.props.arquivos.map(
            a => <ListaArquivoItem
                key={a.idArquivo}
                arquivo={a}
                parceiro={this.props.parceiro}
                downloadHandler={this.props.downloadHandler}
                deleteHandler={this.props.deleteHandler}
            />
        )

        return (
            <div>
                {
                    this.props.arquivos.length > 0
                        ? arquivosComponent
                        : 'Não há arquivos para este parceiro'
                }
            </div>
        )
    }
}