import React from 'react'
import { Col, Row, Container, Label, Input } from 'reactstrap'
import EnderecoService from '../../../service/EnderecoService'

export default class EnderecoParceiro extends React.Component {

    constructor(props) {
        super(props)
        this.enderecoService = new EnderecoService()
    }

    buscaEnderecoByCep = async (event) => {

        if (event.key === 'Enter') {

            const cep = this.props.parceiro.endereco.cep

            if (cep) {
                try {
                    let endereco = await this.enderecoService.getByCep(cep)

                    const parceiro = this.props.parceiro

                    parceiro.endereco.logradouro = endereco.logradouro
                    parceiro.endereco.complemento = endereco.complemento
                    parceiro.endereco.bairro = endereco.bairro
                    parceiro.endereco.cidade = endereco.localidade

                    this.setState({})

                } catch (error) {
                    alert('Cep não localizado')
                }
            }
        }
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col md="7">
                        <Label for="logradouro">Endereço</Label>
                        <Input id="logradouro" data-classe="endereco" className="input" value={this.props.parceiro.endereco.logradouro} onChange={this.props.updateHandler} />
                    </Col>
                    <Col md="2">
                        <Label for="numero">Nº</Label>
                        <Input id="numero" className="input" data-classe="endereco" value={this.props.parceiro.endereco.numero} onChange={this.props.updateHandler} />
                    </Col>
                    <Col md="3">
                        <Label for="complemento">Complemento</Label>
                        <Input id="complemento" className="input" data-classe="endereco" value={this.props.parceiro.endereco.complemento} onChange={this.props.updateHandler} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label for="bairro">Bairro</Label>
                        <Input id="bairro" className="input" data-classe="endereco" value={this.props.parceiro.endereco.bairro} onChange={this.props.updateHandler} />
                    </Col>
                    <Col>
                        <Label for="cidade">Cidade</Label>
                        <Input id="cidade" className="input" data-classe="endereco" value={this.props.parceiro.endereco.cidade} onChange={this.props.updateHandler} />
                    </Col>
                    <Col>
                        <Label for="cep">CEP</Label>
                        <Input id="cep" className="input" data-classe="endereco" value={this.props.parceiro.endereco.cep} onChange={this.props.updateHandler}  onKeyPress={this.buscaEnderecoByCep}  />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label for="pontoReferencia">Ponto Referência</Label>
                        <Input id="pontoReferencia" data-classe="endereco" className="input" value={this.props.parceiro.endereco.pontoReferencia} onChange={this.props.updateHandler} />
                    </Col>
                </Row>
            </Container>
        )
    }
}