import React from 'react'
import { Col, Row, Container, Label, Input, Table, Button, Alert } from 'reactstrap'
import { FaTrash } from 'react-icons/fa'
import { sleep } from '../../../lib/util'
import { mascaraCelular, mascaraTelefoneFixo } from '../../../lib/mascara'

export default class CadPessoaContato extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pessoa: props.pessoa,
            telefone: {
                ddd: '21'
            },
            msg: '',
            msgTipo: '',
            msgVisivel: false
        }
    }

    update = (e) => {

        let telefone = this.state.telefone

        if (e.target.checked) {
            telefone[`${e.target.id}`] = e.target.checked
        } else {
            let field
            if (e.target.id === 'telefone') {
                field = 'numero'
            } else {
                field = `${e.target.id}`
            }

            let value = e.target.value
            let mask
            if (value.charAt(0) === '9') {
                mask = mascaraCelular
            } else {
                mask = mascaraTelefoneFixo
            }

            telefone[field] = mask(e.target.value)
        }

        this.setState({ telefone })
    }

    addTelefoneRow = () => {

        this.limpaMsg()

        let erro
        let erroMsg
        if (!this.state.telefone.ddd || !this.state.telefone.numero) {
            erroMsg = 'Informe o DDD e o numero do telefone antes de adicionar'
            erro = true
        } else {
            for (const tel of this.state.pessoa.telefones) {
                if (tel.ddd === this.state.telefone.ddd
                    && tel.numero === this.state.telefone.numero) {
                    erroMsg = 'Telefone ja incluso na lista'
                    erro = true
                    break
                }
            }
        }

        if (erro) {
            this.setState({
                msg: erroMsg,
                msgTipo: 'danger',
                msgVisivel: true
            })
            sleep(5000, this.limpaMsg)
            return
        }

        let pessoa = this.state.pessoa
        pessoa.telefones.push(Object.assign({}, this.state.telefone))

        this.setState({
            pessoa,
            telefone: {
                ddd: '21',
                numero: '',
                whatsapp: false
            }
        })
    }

    removeTelefoneRow = (index) => {

        let pessoa = this.state.pessoa
        pessoa.telefones.splice(index, 1)

        this.setState({ pessoa })
    }

    limpaMsg = () => {
        this.setState({
            msg: '',
            msgTipo: '',
            msgVisivel: false
        })
    }

    render() {

        let telefones = this.state.pessoa.telefones.map((item, index) => {
            return (<tr key={index}>
                <td>{item.ddd}</td>
                <td>{item.numero}</td>
                <td><Input type="checkbox" style={{ marginLeft: '30px' }} checked={item.whatsapp} readOnly /></td>
                <td>
                    <FaTrash id={index} size="0.8em" color="red" onClick={this.removeTelefoneRow.bind(this, index)} />
                </td>
            </tr>)
        })

        return (
            <Container style={{ marginTop: '20px' }}>
                <Alert color={this.state.msgTipo} isOpen={this.state.msgVisivel} toggle={() => this.limpaMsg()}>
                    {this.state.msg}
                </Alert>
                <Row>
                    <Col md="5">
                        <Row>
                            <Col md="3">
                                <Label for="ddd">DDD</Label>
                                <Input id="ddd" value={this.state.telefone.ddd} onChange={this.update} maxLength="3" />
                            </Col>
                            <Col md="9">
                                <Label for="telefone">Telefone</Label>
                                <Input id="telefone" value={this.state.telefone.numero} onChange={this.update}  />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label check style={{ marginLeft: '21px', marginTop: '20px' }}>
                                    <Input id="whatsapp" type="checkbox" checked={this.state.telefone.whatsapp} onChange={this.update} />
                                    Whatsapp
                                </Label>
                            </Col>
                            <Col>
                                <Button color="primary" size="sm" style={{ marginLeft: '80px', marginTop: '20px' }} onClick={this.addTelefoneRow} >
                                    Adicionar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={7} md={6} className="mr-auto ml-auto" >
                        <Table striped >
                            <thead>
                                <tr>
                                    <th>DDD</th>
                                    <th>Telefone</th>
                                    <th>Whatsapp</th>
                                    <th>{' '}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {telefones}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container >
        )
    }
}