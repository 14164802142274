import API from '../lib/api'

export default class PessoaService {

    constructor() {
        this.baseURL = 'usuario'
        this.login = this.login.bind(this)
    }

    async login(user) {
        const resp = await API.post(`${this.baseURL}/login`, user)
        return resp.data
    }
}