import React from 'react'
import { Col, Row, Container, Label, Input } from 'reactstrap'
import EnderecoService from '../../../service/EnderecoService'

export default class CadPessoaEndereco extends React.Component {

    constructor(props) {
        super(props)
        this.update = this.update.bind(this)
        this.state = {
            pessoa: props.pessoa
        }
        this.enderecoService = new EnderecoService()
    }

    update(e) {
        let pessoa = this.state.pessoa
        pessoa.endereco[`${e.target.id}`] = e.target.value
        this.setState({ pessoa })
    }

    buscaEnderecoByCep = async (event) => {

        if (event.key === 'Enter') {

            const cep = this.state.pessoa.endereco.cep

            if (cep) {
                try {
                    let endereco = await this.enderecoService.getByCep(cep)

                    const pessoa = this.state.pessoa

                    pessoa.endereco.logradouro = endereco.logradouro
                    pessoa.endereco.complemento = endereco.complemento
                    pessoa.endereco.bairro = endereco.bairro
                    pessoa.endereco.cidade = endereco.localidade

                    this.setState({ pessoa })
                } catch (error) {
                    alert('Cep não localizado')
                }
            }
        }
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col md="7">
                        <Label for="logradouro">Endereço</Label>
                        <Input id="logradouro" className="input" value={this.state.pessoa.endereco.logradouro} onChange={this.update} />
                    </Col>
                    <Col md="2">
                        <Label for="numero">Nº</Label>
                        <Input id="numero" className="input" value={this.state.pessoa.endereco.numero} onChange={this.update} />
                    </Col>
                    <Col md="3">
                        <Label for="complemento">Complemento</Label>
                        <Input id="complemento" className="input" value={this.state.pessoa.endereco.complemento} onChange={this.update} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label for="bairro">Bairro</Label>
                        <Input id="bairro" className="input" value={this.state.pessoa.endereco.bairro} onChange={this.update} />
                    </Col>
                    <Col>
                        <Label for="cidade">Cidade</Label>
                        <Input id="cidade" className="input" value={this.state.pessoa.endereco.cidade} onChange={this.update} />
                    </Col>
                    <Col>
                        <Label for="cep">CEP</Label>
                        <Input id="cep" className="input" value={this.state.pessoa.endereco.cep} onChange={this.update} onKeyPress={this.buscaEnderecoByCep} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label for="pontoReferencia">Ponto Referência</Label>
                        <Input id="pontoReferencia" className="input" value={this.state.pessoa.endereco.pontoReferencia} onChange={this.update} />
                    </Col>
                </Row>
            </Container>
        )
    }
}