import React from 'react'
import {
    Row,
    Col,
    Input,
    Label,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Alert,
} from 'reactstrap'
import { GerenciarUsuarioContext } from './context/GerenciarUsuarioContext'

export default class DialogCadastroUsuario extends React.Component {

    static contextType = GerenciarUsuarioContext

    constructor(props) {
        super(props)
        this.state = {
            titulo: 'Cadastro de Usuário',
            usuario: {},
            isEdicao: false,
            isConcluido: false,
            msg: {
                text: '',
                tipo: '',
                visivel: false
            }
        }
    }

    async componentDidMount() {
        let { usuario } = this.props
        if (usuario) {
            usuario = Object.assign({}, usuario)
            this.setState({
                usuario,
                isEdicao: true,
                titulo: 'Redefinir senha do Usuario'
            })
        }
    }

    update = ({ target }) => {
        const usuario = this.state.usuario
        usuario[`${target.id}`] = target.value
        this.setState({ usuario })
    }

    cadastraUsuario = async () => {

        const { login, senha } = this.state.usuario
        const { usuarioService } = this.context

        if (!login || !senha) {
            this.setState({
                msg: {
                    tipo: 'danger',
                    text: 'Informe o usuario e senha antes de confirmar',
                    visivel: true
                }
            })
            return
        }

        try {
            if (this.state.isEdicao) {
                await usuarioService.altera(this.state.usuario)
            } else {
                await usuarioService.inclui(Object.assign({ ativo: true }, this.state.usuario))
                this.props.afterSuccessCadastro()
            }
            this.setState({
                msg: {
                    tipo: 'success',
                    text: this.state.isEdicao ? 'Senha redefinida com sucesso' : 'Usuário cadastrado com sucesso',
                    visivel: true
                },
                isConcluido: true
            })
        } catch (error) {
            this.setState({
                msg: {
                    tipo: 'danger',
                    text: error.response ? error.response.data.error : error.message,
                    visivel: true
                }
            })
        }
    }

    reset = () => {
        
        const usuario = this.state.usuario
        
        if(!this.state.isEdicao) {
            usuario.login = ''
        }
        usuario.senha = ''

        this.setState({
            isConcluido: false,
            usuario,
            msg: { visivel: false }
        })
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                backdrop={true}
                centered={true}
                onClosed={this.reset}
                autoFocus={false}
            >
                <ModalHeader className="alert alert-info">
                    <div>
                        <h4>{this.state.titulo}</h4>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Alert
                        color={this.state.msg.tipo}
                        isOpen={this.state.msg.visivel}
                        children={this.state.msg.text}
                    />
                    {
                        !this.state.isConcluido &&
                        <div>
                            <Row style={{ marginBottom: '15px' }}>
                                <Col>
                                    <Label for="login">Usuário</Label>
                                    <Input
                                        id="login"
                                        autoFocus={true}
                                        disabled={this.state.isEdicao}
                                        value={this.state.usuario.login}
                                        onChange={this.update}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginBottom: '15px' }}>
                                <Col>
                                    <Label for="senha">Senha</Label>
                                    <Input type="password" autoFocus={this.state.isEdicao} id="senha" value={this.state.usuario.senha} onChange={this.update} />
                                </Col>
                            </Row>
                        </div>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" hidden={this.state.isConcluido} onClick={this.cadastraUsuario}>Confirmar</Button>
                    <Button color="secondary" onClick={this.props.toogle}>Fechar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}