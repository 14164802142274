import React from 'react';
import { Switch, Route, Redirect, Link, HashRouter } from 'react-router-dom'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText,
    Popover,
    PopoverHeader,
    PopoverBody,
    Progress,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from 'reactstrap';
import logo from '../logo.png'
import FileDownload from 'js-file-download'
import ReactLoading from "react-loading"

import "./style.css"

import ProvideAuth from './auth/ProvideAuth'
import PrivateRoute from './auth/PrivateRoute'
import Login from './auth/Login'

import GerenciarUsuario from './usuario/GerenciarUsuario';

import CadPessoa from './pessoa/cadpessoa/CadPessoa'
import GerenciarPessoa from './pessoa/gerenciarpessoa/GerenciarPessoa'
import ViewPessoa from './pessoa/viewpessoa/ViewPessoa'
import ListaAssinatura from './pessoa/listaassinatura/ListaAssinatura'

import CadParceiro from './parceiro/cadparceiro/CadParceiro'
import GerenciarParceiro from './parceiro/gerenciarparceiro/GerenciarParceiro'
import ViewParceiro from './parceiro/viewparceiro/ViewParceiro'
import BackupService from '../service/BackupService';

const StatusDownloadEnum = Object.freeze({ "PROCESSANDO": '0', "BAIXANDO": '1' })

export default class Main extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            isShowNavBar: false,
            logout: () => { },
            usuarioLogado: '',
            isEfetuandoBackup: false,
            statusDownload: '',
            backupMsg: '',
            backupProgressCount: 0,
            isPermiteGerenciarUsuarios: false,
            isPermiteGerarBackup: false,
            msg: {
                alertClass: 'alert alert-warning',
                isOpen: false,
                titulo: '',
                text: '',
                confirmHandler: () => { },
                cancelHandler: () => { },
            }
        }
        this.backupService = new BackupService()
    }

    showNavBar = () => {
        this.setState({ isShowNavBar: true })
    }

    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    setLogout = (cb) => {
        this.setState({ logout: cb })
    }

    setUsuarioLogado = (usuarioLogado) => {
        this.setState({ usuarioLogado })
    }

    gerarBackup = async () => {

        this.setState({
            msg: {
                alertClass: 'alert alert-warning',
                isOpen: true,
                titulo: 'Confirmação de backup',
                text: 'Deseja efetuar o backup dos dados?',
                confirmHandler: async () => {
                    try {
                        this.setState({ isEfetuandoBackup: true, msg: { isOpen: false }, backupMsg: 'Processando backup...', statusDownload: StatusDownloadEnum.PROCESSANDO })
                        await this.backupService.gerarBackup()
                        this.verificaProcessamentoBackup()
                    } catch (error) {
                        alert(error.response ? error.response.data.message : error.message)
                        this.setState({ isEfetuandoBackup: false })
                    }
                },
                cancelHandler: () => {
                    this.setState({
                        msg: {
                            isOpen: false
                        }
                    })
                }
            }
        })

    }

    verificaProcessamentoBackup = async () => {
        try {
            const isProcessando = await this.backupService.statusBackup()
            if (isProcessando) {
                setTimeout(this.verificaProcessamentoBackup, 1000)
            } else {
                this.downloadBackup()
            }
        } catch (error) {
            alert(error.response ? error.response.data.message : error.message)
            this.setState({ isEfetuandoBackup: false })
        }
    }

    downloadBackup = async () => {
        try {
            this.setState({ backupMsg: 'Baixando backup...', statusDownload: StatusDownloadEnum.BAIXANDO })
            const data = await this.backupService.downloadBackup(this.downloadProgressHandler)
            FileDownload(data, 'riodepaz.backup')
        } catch (error) {
            alert(error.response ? error.response.data.message : error.message)
            this.setState({ isEfetuandoBackup: false })
        }
    }

    downloadProgressHandler = (progressEvent) => {
        const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
        )
        this.setState({ backupProgressCount: percentage })
        if (percentage === 100) {
            this.setState({ backupMsg: 'Backup concluído' })
            setTimeout(() => {
                this.setState({
                    isEfetuandoBackup: false,
                    statusDownload: '',
                    backupMsg: '',
                    backupProgressCount: 0
                })
            }, 6000);
        }
    }

    setPermissoesMenu = (isPermiteGerenciarUsuarios, isPermiteGerarBackup) => {
        this.setState({
            isPermiteGerenciarUsuarios,
            isPermiteGerarBackup
        })
    }

    render() {
        return (
            <div>
                <ProvideAuth
                    showNavBarHandler={this.showNavBar}
                    setLogout={this.setLogout}
                    setUsuarioLogado={this.setUsuarioLogado}
                    setPermissoesMenu={this.setPermissoesMenu}
                >
                    <HashRouter>
                        <div>
                            {
                                this.state.isShowNavBar
                                &&
                                <Navbar color="primary" light expand="md" className="py-0" fixed="top">

                                    <NavbarBrand href="/" style={{ marginBottom: '8px' }}>
                                        <img id="logo" src={logo} alt="logo" />
                                    </NavbarBrand>

                                    <Popover
                                        placement="bottom"
                                        isOpen={this.state.isEfetuandoBackup}
                                        target="menu-dropdown"
                                    >
                                        <PopoverHeader>Efetuando Backup dos dados</PopoverHeader>
                                        <PopoverBody>
                                            {this.state.backupMsg}
                                            {
                                                this.state.statusDownload === StatusDownloadEnum.PROCESSANDO
                                                &&
                                                <div style={{ float: 'right' }}>
                                                    <ReactLoading height="17px" width="17px" type="spin" color="#0069D9" />
                                                </div>
                                            }
                                            {
                                                this.state.statusDownload === StatusDownloadEnum.BAIXANDO
                                                &&
                                                <div style={{ marginTop: '15px' }}>
                                                    {this.state.backupProgressCount}%
                                                    <Progress value={this.state.backupProgressCount} />
                                                </div>
                                            }
                                        </PopoverBody>
                                    </Popover>

                                    <NavbarToggler onClick={this.toggle} />

                                    <Collapse isOpen={this.state.isOpen} navbar>
                                        <Nav className="mr-auto" navbar>
                                            <NavItem>
                                                <Link
                                                    style={{ color: '#F7F7F7', textDecoration: 'none', marginLeft: '10px' }}
                                                    to="/pessoa"
                                                >
                                                    Pessoa
                                                </Link>
                                            </NavItem>
                                            <NavItem>
                                                <Link
                                                    style={{ color: '#F7F7F7', textDecoration: 'none', marginLeft: '20px' }}
                                                    to="/parceiro"
                                                >
                                                    Parceiro
                                                </Link>
                                            </NavItem>
                                        </Nav>
                                    </Collapse>

                                    <Nav className='rightNav ml-auto' navbar>
                                        <UncontrolledDropdown nav inNavbar>
                                            <DropdownToggle nav caret style={{ marginRight: '20px' }}>
                                                <NavbarText>Usuário logado: </NavbarText>
                                                <NavbarText
                                                    id="menu-dropdown"
                                                    style={{ color: '#F7F7F7', marginLeft: '10px' }}
                                                >
                                                    {this.state.usuarioLogado}
                                                </NavbarText>
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                                <DropdownItem hidden={!this.state.isPermiteGerenciarUsuarios}>
                                                    <Link
                                                        style={{ color: 'inherit', textDecoration: 'none' }}
                                                        to="/usuario"
                                                    >
                                                        Gerenciar Permissões
                                                    </Link>
                                                </DropdownItem>
                                                <DropdownItem divider hidden={!this.state.isPermiteGerenciarUsuarios} />
                                                <DropdownItem
                                                    hidden={!this.state.isPermiteGerarBackup}
                                                    onClick={this.gerarBackup}
                                                >
                                                    Backup
                                                </DropdownItem>
                                                <DropdownItem hidden={!this.state.isPermiteGerarBackup} divider />
                                                <DropdownItem onClick={this.state.logout}>
                                                    Logout
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </Nav>

                                </Navbar>
                            }
                            <Switch>
                                <Route exact path="/">
                                    <Redirect to='/pessoa' />
                                </Route>

                                <Route exact path="/login" component={Login} />
                                <Route path="/login/:info" component={Login} />

                                <PrivateRoute path="/usuario">
                                    <GerenciarUsuario />
                                </PrivateRoute>

                                <PrivateRoute exact path="/pessoa">
                                    <GerenciarPessoa />
                                </PrivateRoute>

                                <PrivateRoute path="/pessoa/cadastro">
                                    <CadPessoa />
                                </PrivateRoute>

                                <PrivateRoute path="/pessoa/editar/:id">
                                    <CadPessoa />
                                </PrivateRoute>

                                <PrivateRoute path="/pessoa/visualizar/:id">
                                    <ViewPessoa />
                                </PrivateRoute>

                                <PrivateRoute path="/pessoa/lista/:props">
                                    <ListaAssinatura />
                                </PrivateRoute>

                                <PrivateRoute exact path="/parceiro">
                                    <GerenciarParceiro />
                                </PrivateRoute>

                                <PrivateRoute path="/parceiro/cadastro">
                                    <CadParceiro />
                                </PrivateRoute>

                                <PrivateRoute path="/parceiro/editar/:id">
                                    <CadParceiro />
                                </PrivateRoute>

                                <PrivateRoute path="/parceiro/visualizar/:id">
                                    <ViewParceiro />
                                </PrivateRoute>
                            </Switch>
                        </div>
                    </HashRouter>
                </ProvideAuth>
                <Modal
                    isOpen={this.state.msg.isOpen}
                    backdrop={true}
                >
                    <ModalHeader className={this.state.msg.alertClass}>
                        <div>
                            <h4>{this.state.msg.titulo}</h4>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        {this.state.msg.text}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.state.msg.confirmHandler}>Confirmar</Button>
                        <Button color="secondary" onClick={this.state.msg.cancelHandler}>Fechar</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}