import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { Alert } from 'reactstrap';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
// import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import logo from '../../rdp-logo.jpg'
import AuthService from '../../service/AuthService'
import { AuthContext } from './context/AuthContext'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="http://appforadacaixa.com.br/">
                Fora da Caixa
            </Link>{' '}
            {'2021'}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        marginBottom: theme.spacing(4),
        height: theme.spacing(15),
        width: theme.spacing(15),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Login() {

    const auth = useContext(AuthContext)
    const classes = useStyles()
    const history = useHistory()
    let { info } = useParams()

    const [user, setUser] = useState({})
    const [manterLogado, setManterLogado] = useState(false)
    const [msg, setMsg] = useState({
        text: '',
        tipo: '',
        visivel: false
    })

    const authService = new AuthService()

    useEffect(() => {

        if (auth.isLogado()) {
            history.goBack()
        }

        if(info) {
            setMsg({
                tipo: 'info',
                text: window.atob(info),
                visivel: true
            })
        }
    }, [])

    function update(event) {

        const userUpdate = user

        if (event.target.id === 'manterLogado') {
            setManterLogado(event.target.checked)
        } else {
            userUpdate[`${event.target.id}`] = event.target.value
            setUser(userUpdate)
        }
    }

    async function submitHandler(event) {
        event.preventDefault()
        setMsg({ visivel: false }, fazerLogin())
    }

    async function fazerLogin() {

        try {
            const authData = await authService.login(user)

            auth.login(
                authData,
                manterLogado
            )

        } catch (error) {
            setMsg({
                tipo: 'danger',
                text: error.response ? error.response.data.message : error.message,
                visivel: true
            })
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar} alt="Rio de paz" src={logo} />
                <form method="post" className={classes.form} noValidate onSubmit={submitHandler}>
                    <Alert
                        color={msg.tipo}
                        isOpen={msg.visivel}
                        children={ msg.text}
                        toggle={() => setMsg({ visivel: false })}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="login"
                        label="Usuário"
                        name="login"
                        autoComplete="login"
                        autoFocus
                        onChange={update}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="senha"
                        label="Senha"
                        type="password"
                        id="senha"
                        autoComplete="current-password"
                        onChange={update}
                    />
                    <FormControlLabel
                        control={<Checkbox id="manterLogado" color="primary" />}
                        label="Manter Logado"
                        onChange={update}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Login
                    </Button>
                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Esqueceu a senha?
                            </Link>
                        </Grid>
                    </Grid> */}
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}