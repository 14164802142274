import React from 'react'
import { Row, Col } from 'reactstrap'

export default class ListaAssinatura extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <div style={{
                backgroundColor: this.props.zebra ? '#E8EAED' : 'none',
                padding: '3px 10px'
            }}
            >
                <Row>
                    <Col>
                        {this.props.pessoa.nome}
                    </Col>
                    <Col>
                        {`RG: ${this.props.pessoa.documentos.rg ? this.props.pessoa.documentos.rg : ''}`}
                    </Col>
                    <Col>
                        {'___________________________________________________'}
                    </Col>
                </Row>
            </div>
        )
    }
}