import React from 'react'
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    CardTitle,
    CardText,
    Collapse,
    Button,
    Fade,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import { FaWhatsapp, FaArchive } from 'react-icons/fa'
import { withRouter } from 'react-router-dom'
import { formatter } from '../../../lib/util'
import DialogGerenciarArquivo from '../gerenciararquivo/DialogGerenciarArquivo'

import { GerenciarParceiroContext } from './context/GerenciarParceiroContext'

class ListaParceiroItem extends React.Component {

    static contextType = GerenciarParceiroContext

    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            isOpenDialogArquivo: false,
            msg: {
                alertClass: 'alert alert-warning',
                isOpen: false,
                titulo: '',
                text: '',
                confirmHandler: () => { },
                cancelHandler: () => { },
            }
        }
    }

    visualizar = () => {
        const ecodedId = window.btoa(this.props.parceiro.id)
        this.props.history.push(`/parceiro/visualizar/${ecodedId}`)
    }

    editar = () => {

        this.setState({
            msg: {
                alertClass: 'alert alert-warning',
                isOpen: true,
                titulo: 'Editar Cadastro',
                text: `Deseja editar o cadastro do parceiro ${this.props.parceiro.nome}`,
                confirmHandler: async () => {
                    const ecodedId = window.btoa(this.props.parceiro.id)
                    this.props.history.push(`/parceiro/editar/${ecodedId}`)
                },
                cancelHandler: () => {
                    this.setState({
                        msg: {
                            isOpen: false
                        }
                    })
                }
            }
        })
    }

    gerenciarArquivos = () => {
        this.setState({ isOpenDialogArquivo: true })
    }

    render() {

        const endereco = this.props.parceiro.endereco
        const enderecoCompleto = `
            ${endereco.logradouro ? `${endereco.logradouro},` : ''}
            ${endereco.numero ? endereco.numero : ''}
            ${endereco.complemento ? `${endereco.complemento}.` : ''}
            ${endereco.bairro ? `${endereco.bairro} / ` : ''}
            ${endereco.cidade ? endereco.cidade : ''}
            ${endereco.cep ? `(${formatter.cep(endereco.cep)})` : ''}
        `

        const length = this.props.parceiro.telefones.length
        const telefones = this.props.parceiro.telefones.map(
            (tel, index) =>
            (
                <div style={{ display: 'inline' }}>
                    {formatter.telefone(tel.ddd, tel.numero)}
                    {' '}
                    {tel.whatsapp && <FaWhatsapp style={{ marginBottom: '5px' }} color="#2DB843" />}
                    {(length > 0 && index !== (length - 1)) && ', '}
                </div>
            )
        )

        return (
            <div>
                <Fade>
                    <Card style={{ marginTop: '15px' }} >
                        <CardHeader className="alert-info" style={{ cursor: 'pointer' }} onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
                            <Row>
                                <Col>
                                    <CardTitle tag="h6" style={{ height: '8px', fontSize: '16px' }}>{this.props.parceiro.nome}</CardTitle>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Collapse isOpen={this.state.isOpen}>
                            <CardBody>
                                <Row>
                                    <Col xl="2">
                                        <CardTitle tag="h6">CNPJ: </CardTitle>
                                    </Col>
                                    <Col>
                                        <CardText>{formatter.cnpj(this.props.parceiro.cnpj)}</CardText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="2">
                                        <CardTitle tag="h6">E-mail: </CardTitle>
                                    </Col>
                                    <Col>
                                        <CardText>{this.props.parceiro.email}</CardText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="2">
                                        <CardTitle tag="h6">Endereço: </CardTitle>
                                    </Col>
                                    <Col>
                                        <CardText>{enderecoCompleto}</CardText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="2">
                                        <CardTitle tag="h6">Telefones: </CardTitle>
                                    </Col>
                                    <Col>
                                        <CardText>{telefones}</CardText>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col md="1">
                                        <Button size="sm" onClick={this.visualizar}>Visualizar</Button>
                                    </Col>
                                    <Col md="1">
                                        <Button
                                            disabled={!this.context.isPermiteAlteraParceiro}
                                            size="sm"
                                            onClick={this.editar}
                                        >
                                            Editar
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button size="sm" style={{ float: 'right' }} onClick={this.gerenciarArquivos}>
                                            {'Genreciar Arquivos '}
                                            <FaArchive style={{ marginBottom: '3px', marginLeft: '5px' }} />
                                        </Button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Collapse>
                    </Card>
                </Fade>
                <Modal
                    isOpen={this.state.msg.isOpen}
                    backdrop={true}
                >
                    <ModalHeader className={this.state.msg.alertClass}>
                        <div>
                            <h4>{this.state.msg.titulo}</h4>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        {this.state.msg.text}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.state.msg.confirmHandler}>Confirmar</Button>
                        <Button color="secondary" onClick={this.state.msg.cancelHandler}>Fechar</Button>
                    </ModalFooter>
                </Modal>
                <DialogGerenciarArquivo
                    isOpen={this.state.isOpenDialogArquivo}
                    toggle={() => this.setState({ isOpenDialogArquivo: false })}
                    parceiro={this.props.parceiro}
                />
            </div>
        )
    }
}

export default withRouter(ListaParceiroItem)