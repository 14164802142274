import React from 'react'
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    CardTitle,
    Collapse,
    Label,
    Input,
    Button,
    Fade,
    Row,
    Col,
} from 'reactstrap'
import DialogCadastroUsuario from './DialogCadastroUsuario'
import { GerenciarUsuarioContext } from './context/GerenciarUsuarioContext'

export default class UsuarioListaItem extends React.Component {

    static contextType = GerenciarUsuarioContext

    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            isOpenRedefinirSenha: false,
            usuarioPermissoes: [],
            ativo: props.usuario.ativo
        }
    }

    componentDidMount() {
        this.setUpUsuarioPermissoes()
    }

    // permissoes de usuario - begin
    setUpUsuarioPermissoes() {
        const usuarioPermissoes = this.context.permissoes.map((p) => Object.assign({ hasPermissao: this.isPermissaoIncluded(p) }, p))
        this.setState({ usuarioPermissoes })
    }

    isPermissaoIncluded(permissao) {
        const { usuario } = this.props
        const index = usuario.permissoes.findIndex((el) => el.authority === permissao.nome)
        return index !== -1
    }

    removePermissaoUsuario = (permissao) => {
        const { usuario } = this.props
        return usuario.permissoes.filter(p => p.authority !== permissao.nome)
    }

    checkToogleHandler = async (permissao, { target }) => {

        const isAllowed = target.checked
        const { usuarioService } = this.context
        const { usuario } = this.props

        try {
            if (isAllowed) {
                const permissaoAdicionada = await usuarioService.addPermissao(usuario.id, permissao.nome)
                usuario.permissoes.push(permissaoAdicionada)
            } else {
                await usuarioService.removePermissao(usuario.id, permissao.nome)
                usuario.permissoes = this.removePermissaoUsuario(permissao)
            }
            this.setUpUsuarioPermissoes()
        } catch (error) {
            alert.log(error.response ? error.response.data.message : error.message)
        }
    }
    // permissoes de usuario - end

    toogleAtividadeUsuario = async () => {
        const { usuario } = this.props
        const { usuarioService } = this.context
        try {
            usuario.ativo = !usuario.ativo
            await usuarioService.alterarStatus(usuario.id, usuario.ativo)
            this.setState({ ativo: usuario.ativo })
        } catch (error) {
            alert.log(error.response ? error.response.data.message : error.message)
        }
    }

    render() {
        return (
            <div>

                <Fade>
                    <Card style={{ marginTop: '15px' }} >
                        <CardHeader className={this.state.ativo ? 'alert-info' : 'alert-danger'} style={{ cursor: 'pointer' }} onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
                            <Row>
                                <Col>
                                    <CardTitle tag="h6" style={{ height: '8px', fontSize: '16px' }}>{this.props.usuario.login}</CardTitle>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Collapse isOpen={this.state.isOpen}>
                            <CardBody>
                                {
                                    this.state.usuarioPermissoes.map(
                                        (p) => (
                                            <Row key={p.nome}>
                                                <Col style={{ marginLeft: '30px' }}>
                                                    <Label check style={{ marginLeft: '21px', marginTop: '3px', cursor: 'pointer' }}>
                                                        <Input
                                                            id={p.nome}
                                                            type="checkbox"
                                                            style={{ cursor: 'pointer' }}
                                                            checked={p.hasPermissao}
                                                            onChange={this.checkToogleHandler.bind(this, p)}
                                                        />
                                                        {p.descricao}
                                                    </Label>
                                                </Col>
                                            </Row>
                                        )
                                    )
                                }
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col>
                                        <Button
                                            size="sm"
                                            color="primary"
                                            style={{ float: 'left' }}
                                            onClick={() => this.setState({ isOpenRedefinirSenha: true })}
                                        >
                                            Redefinir Senha
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            size="sm"
                                            color={this.state.ativo ? 'danger' : 'primary'}
                                            style={{ float: 'right' }}
                                            onClick={this.toogleAtividadeUsuario}
                                        >
                                            {this.state.ativo ? 'Inativar' : 'Ativar'}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Collapse>
                    </Card>
                </Fade>
                <DialogCadastroUsuario
                    isOpen={this.state.isOpenRedefinirSenha}
                    toogle={() => this.setState({ isOpenRedefinirSenha: false })}
                    usuario={this.props.usuario}
                />
            </div>
        )
    }
}