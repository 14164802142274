import React from 'react'
import { Col, Row, Container, Label, Input } from 'reactstrap'

export default class CadPessoaQuantitativoFamiliar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pessoa: props.pessoa
        }
    }

    update = (e) => {
        let pessoa = this.state.pessoa
        pessoa.quantitativoFamiliar[`${e.target.id}`] = e.target.value
        this.setState({ pessoa })
    }

    render() {

        let quantitativoFamiliar = this.state.pessoa.quantitativoFamiliar

        let qtdTotal = 0
        qtdTotal += quantitativoFamiliar.crianca0a6 ? parseInt(quantitativoFamiliar.crianca0a6) : 0
        qtdTotal += quantitativoFamiliar.crianca7a12 ? parseInt(quantitativoFamiliar.crianca7a12) : 0
        qtdTotal += quantitativoFamiliar.adolescente13a17 ? parseInt(quantitativoFamiliar.adolescente13a17) : 0
        qtdTotal += quantitativoFamiliar.adulto ? parseInt(quantitativoFamiliar.adulto) : 0
        qtdTotal += quantitativoFamiliar.idoso ? parseInt(quantitativoFamiliar.idoso) : 0

        return (
            <Container>
                <Row>
                    <Col>
                        <Label for="crianca0a6">Crianças 0 a 6</Label>
                        <Input type="number" id="crianca0a6" className="input" value={this.state.pessoa.quantitativoFamiliar.crianca0a6} onChange={this.update} />
                    </Col>
                    <Col>
                        <Label for="crianca7a12">Crianças 7 a 12</Label>
                        <Input type="number" id="crianca7a12" className="input" value={this.state.pessoa.quantitativoFamiliar.crianca7a12} onChange={this.update} />
                    </Col>
                    <Col>
                        <Label for="adolescente13a17">Adolescente 13 a 17</Label>
                        <Input type="number" id="adolescente13a17" className="input" value={this.state.pessoa.quantitativoFamiliar.adolescente13a17} onChange={this.update} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label for="adulto">Adultos</Label>
                        <Input type="number" id="adulto" className="input" value={this.state.pessoa.quantitativoFamiliar.adulto} onChange={this.update} />
                    </Col>
                    <Col>
                        <Label for="idoso">Idosos</Label>
                        <Input type="number" id="idoso" className="input" value={this.state.pessoa.quantitativoFamiliar.idoso} onChange={this.update} />
                    </Col>
                    <Col>
                        <Label for="total">Total</Label>
                        <Input id="total" className="input" value={qtdTotal} readOnly />
                    </Col>
                </Row>
            </Container>
        )
    }
}