import React from 'react'
import { Row, Col, Input, Button } from 'reactstrap'
import { isObjectEmpty } from '../../../lib/util'
import { GerenciarPessoaContext } from './context/GerenciarPessoaContext'

export default class FiltroPesquisaFields extends React.Component {

    static contextType = GerenciarPessoaContext

    constructor(props) {
        super(props)
        this.state = {
            index: this.props.index,
            filtro: this.props.filtro,
            comparators: [],
            currentTipoField: ''
        }
        this.filterHasInitialized = false
    }

    componentDidMount() {
        if (!isObjectEmpty(this.state.filtro)) {
            const comparators = Object.assign([], this.props.keyMapCompareConstraints[this.state.filtro.comparator.tipo])
            this.setState({ comparators, currentTipoField: this.state.filtro.comparator.tipo })
        }
    }

    update = (e) => {

        const filtro = this.state.filtro

        switch (e.target.id) {
            case 'field':
                const tipoField = e.target.options[e.target.selectedIndex].getAttribute('data-tipofield')
                const comparators = Object.assign([], this.props.keyMapCompareConstraints[tipoField])
                filtro.field = {
                    nome: e.target.value,
                    classe: e.target.options[e.target.selectedIndex].getAttribute('data-classe'),
                }
                filtro.comparator = comparators[0]
                filtro.value = ''
                this.setState({ comparators, currentTipoField: tipoField, filtro })
                break
            case 'comparator':
                filtro.comparator = {
                    nome: e.target.value,
                    tipo: e.target.options[e.target.selectedIndex].getAttribute('data-tipocomparator')
                }
                this.setState({ filtro })
                break
            default:
                filtro[`${e.target.id}`] = e.target.value
                this.setState({ filtro })

        }

        if (filtro.dtInicioValue && filtro.dtFimValue) {
            filtro.value = filtro.dtInicioValue + '/' + filtro.dtFimValue
        }
    }

    enterHandler = (event) => {
        if (event.key === 'Enter') {
            const { pesquisar } = this.context
            pesquisar(true)
        }
    }

    render() {

        const fieldsComponent = this.props.fieldsPesquisaPessoa.map(
            fpp => <option
                key={fpp.nome}
                value={fpp.nome}
                data-tipofield={fpp.tipoField}
                data-classe={fpp.classe}
            >
                {fpp.descricao}
            </option>
        )
        fieldsComponent.splice(0, 0, <option key={fieldsComponent} value="">Selecione...</option>)

        const comparatorsComponent = this.state.comparators.map(
            cc => <option
                key={cc.nome}
                value={cc.nome}
                data-tipocomparator={cc.tipo}
            >
                {cc.descricao}
            </option>
        )

        let input = ''
        switch (this.state.currentTipoField) {
            case 'DATE':
                if (this.state.filtro.comparator && this.state.filtro.comparator.nome === 'ENTRE') {
                    input = (
                        <Row>
                            <Col >
                                <Input
                                    type="date"
                                    id="dtInicioValue"
                                    value={this.state.filtro.dtInicioValue}
                                    onChange={this.update}
                                    onKeyPress={this.enterHandler}
                                />
                            </Col>
                            <Col >
                                <Input
                                    type="date"
                                    id="dtFimValue"
                                    value={this.state.filtro.dtFimValue}
                                    onChange={this.update}
                                    onKeyPress={this.enterHandler}
                                />
                            </Col>
                        </Row>
                    )
                } else {
                    input = <Input
                        type="date"
                        id="value"
                        value={this.state.filtro.value}
                        onChange={this.update}
                        onKeyPress={this.enterHandler}
                    />
                }
                break
            case 'NUMBER':
                input = <Input
                    type="number"
                    id="value"
                    value={this.state.filtro.value}
                    onChange={this.update}
                    onKeyPress={this.enterHandler}
                />
                break
            case 'BOOLEAN':
                input = (
                    <Input
                        type="select"
                        id="value"
                        value={this.state.filtro.value}
                        onChange={this.update}
                    >
                        <option value="">Selecione...</option>
                        <option value="true">sim</option>
                        <option value="false">não</option>
                    </Input>
                )
                break
            default:
                input = <Input
                    id="value"
                    value={this.state.filtro.value}
                    onChange={this.update}
                    onKeyPress={this.enterHandler}
                />
        }

        return (
            <Row style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Col>
                    <Input type="select" id="field" value={this.state.filtro.field && this.state.filtro.field.nome} onChange={this.update}>
                        {fieldsComponent}
                    </Input>
                </Col>
                <Col>
                    <Input type="select" id="comparator" value={this.state.filtro.comparator.nome} onChange={this.update}>
                        {comparatorsComponent}
                    </Input>
                </Col>
                <Col>
                    {input}
                </Col>
                <Col xl="1">
                    <Button color="link" style={{ color: '#DC3545' }} onClick={this.props.removeFiltroHandler}>
                        Remover
                    </Button>
                </Col>
            </Row>
        )
    }
}