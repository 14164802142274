import React from 'react'
import { Container, Input, Row, Col, Button, Alert } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { FaUserPlus } from 'react-icons/fa'
import sessionStore from '../../../lib/sessionStore'
import ParceiroService from '../../../service/ParceiroService'
import ResourceService from '../../../service/ResourceService'
import ListaParceiro from './ListaParceiro'
import { AuthContext } from '../../auth/context/AuthContext'

import { GerenciarParceiroContext } from './context/GerenciarParceiroContext'

class GerenciarParceiro extends React.Component {

    static contextType = AuthContext

    constructor(props) {
        super(props)
        this.state = {
            filtroPesquisa: {
                filtros: []
            },
            parceiros: [],
            msg: {
                text: '',
                tipo: '',
                visivel: false
            },
            roles: {
                isPermiteCadastraParceiro: false,
                isPermiteAlteraParceiro: false
            }
        }
        this.parceiroService = new ParceiroService()
        this.resourceService = new ResourceService()
    }

    async componentDidMount() {
        this.verificaPermissoes(this.init)
    }

    init = async () => {

        const fieldsPesquisa = await this.resourceService.fieldsPesquisaParceiro()
        const fieldNome = fieldsPesquisa[0]

        const compareConstraints = await this.resourceService.compareConstraints(fieldNome.tipoField)
        const compareContenha = compareConstraints[0]

        const searchQuery = sessionStore.getSearchQueryParceiro()

        const filtroPesquisa = {
            filtros: [{
                field: fieldNome,
                comparator: compareContenha,
                value: searchQuery || ''
            }]
        }

        this.setState({ filtroPesquisa }, () => {
            if (searchQuery !== null) {
                this.pesquisar(false)
            }
        })

    }

    verificaPermissoes = (initCb) => {

        const isPermiteCadastraParceiro = this.hasPermissao('CADASTRAR_PARCEIRO')
        const isPermiteAlteraParceiro = this.hasPermissao('ALTERAR_PARCEIRO')

        this.setState({
            roles: {
                isPermiteCadastraParceiro,
                isPermiteAlteraParceiro
            }
        }, initCb)
    }

    hasPermissao = (roleName) => {
        const roles = this.context.data.roles
        const isPermitido = roles
            .findIndex(role => role === roleName) > -1
        return isPermitido
    }

    update = (event) => {
        const filtroPesquisa = this.state.filtroPesquisa
        filtroPesquisa.filtros[0].value = event.target.value
        this.setState({ filtroPesquisa })
    }

    pesquisarKeyHandler = async (event) => {
        if (event.key === 'Enter') {
            this.pesquisar()
        }
    }

    pesquisar = async (exibeMsg = true) => {
        try {
            this.setState({ msg: { visivel: false } })

            const parceiros = await this.parceiroService.lista(this.state.filtroPesquisa)

            this.setState({ parceiros }, this.saveSessionParams)

            if (exibeMsg && parceiros.length < 1) {
                this.setState({
                    msg: {
                        text: 'A pesquisa não obteve resultados',
                        tipo: 'info',
                        visivel: true
                    }
                })
            }
        } catch (error) {
            this.setState({
                msg: {
                    text: error.response ? error.response.data.message : error.message,
                    tipo: 'danger',
                    visivel: true
                }
            })
        }
    }

    saveSessionParams = () => {
        const filtro = this.state.filtroPesquisa.filtros[0] || {}
        sessionStore.setSearchQueryParceiro(filtro.value)
    }

    redirect = (to) => {
        this.props.history.push(to)
    }

    render() {

        const filtro = this.state.filtroPesquisa.filtros[0] || {}

        return (
            <GerenciarParceiroContext.Provider
                value={{
                    isPermiteAlteraParceiro: this.state.roles.isPermiteAlteraParceiro
                }}
            >
                <Container style={{ marginTop: '50px' }}>
                    <Row>
                        <Col>
                            <Input
                                placeholder="Nome do Parceiro (Enter)"
                                value={filtro.value}
                                onChange={this.update}
                                onKeyPress={this.pesquisarKeyHandler}
                            />
                        </Col>
                        <Col md="3">
                            <Button
                                disabled={!this.state.roles.isPermiteCadastraParceiro}
                                color="success"
                                size="sm"
                                style={{ float: 'right' }}
                                onClick={this.redirect.bind(this, '/parceiro/cadastro')}
                            >
                                {'Novo Parceiro '}
                                <FaUserPlus style={{ marginBottom: '3px', marginLeft: '5px' }} />
                            </Button>
                        </Col>
                    </Row>
                    <Alert
                        style={{ marginTop: '45px' }}
                        color={this.state.msg.tipo}
                        isOpen={this.state.msg.visivel}
                        children={this.state.msg.text}
                        toggle={() => this.setState({ msg: { visivel: false } })}
                    />
                    <Row style={{ marginTop: '30px' }}>
                        <Col>
                            <ListaParceiro parceiros={this.state.parceiros} />
                        </Col>
                    </Row>
                </Container>
            </GerenciarParceiroContext.Provider>
        )
    }
}

export default withRouter(GerenciarParceiro)