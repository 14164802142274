import React from 'react'
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    CardTitle,
    CardText,
    Collapse,
    Button,
    Fade,
    Row,
    Col,
    Badge,
    UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import Avatar from '@material-ui/core/Avatar'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom'
import { FaWhatsapp } from 'react-icons/fa'
import { formatter, isObjectEmpty } from '../../../lib/util'
import logo from '../../../person-icon.png'

import CadPessoaUploadFoto from '../cadpessoa/CadPessoaUploadFoto'
import { GerenciarPessoaContext } from './context/GerenciarPessoaContext';

const styles = theme => ({
    avatar: {
        height: theme.spacing(6),
        width: theme.spacing(6),
        backgroundColor: theme.palette.secondary.main,
    }
})

class ListaPessoaItem extends React.Component {

    static contextType = GerenciarPessoaContext

    constructor(props) {
        super(props)
        this.state = {
            pessoa: this.props.pessoa,
            isOpen: false,
            isUploadFotoOpen: false,
            isUploadFotoEmProgresso: false,
            tooltipOpen: false,
        }
        this.toolTipRef = null
    }

    visualizar = () => {
        const encondedId = window.btoa(this.state.pessoa.id)
        this.props.history.push(`/pessoa/visualizar/${encondedId}`)
    }

    editar = () => {
        this.props.encaminharEdicaoHandler(this.state.pessoa)
    }

    removerPessoaPasta = () => {
        const resultPastaPessoa = this.state.pessoa.listaPastaPessoa.filter(pp => pp.pasta.id === this.props.pastaSelecionada.id)
        if (resultPastaPessoa) {
            this.props.removePessoaPastaHandler(resultPastaPessoa[0], this.state.pessoa)
        }
    }

    adicionaPessoaPasta = () => {
        this.props.adicionaPessoaPastaHandler(this.state.pessoa)
    }

    toogleCollapse = ({ target }) => {
        if (!target.dataset.ignoreCollpase) {
            this.setState({ isOpen: !this.state.isOpen })
        }
    }

    openUploadFoto = () => {
        this.setState({ isUploadFotoOpen: true })
        this.toolTipRef.state.isOpen = false
    }

    closedUploadFotoHandler = () => {
        this.setState({})
    }

    render() {

        const endereco = this.state.pessoa.endereco
        const enderecoCompleto = `
            ${endereco.logradouro ? `${endereco.logradouro},` : ''}
            ${endereco.numero ? endereco.numero : ''}
            ${endereco.complemento ? `${endereco.complemento}.` : ''}
            ${endereco.bairro ? `${endereco.bairro} / ` : ''}
            ${endereco.cidade ? endereco.cidade : ''}
            ${endereco.cep ? `(${formatter.cep(endereco.cep)})` : ''}
        `

        const length = this.state.pessoa.telefones.length
        const telefones = this.state.pessoa.telefones.map(
            (tel, index) =>
            (
                <div style={{ display: 'inline' }}>
                    {formatter.telefone(tel.ddd, tel.numero)}
                    {' '}
                    {tel.whatsapp && <FaWhatsapp style={{ marginBottom: '5px' }} color="#2DB843" />}
                    {(length > 0 && index !== (length - 1)) && ', '}
                </div>
            )
        )

        const pastas = this.state.pessoa.listaPastaPessoa.map(
            (pp, index) =>
            (
                <div key={pp.id} style={{ display: 'inline' }}>
                    <Badge color={index % 2 === 0 ? 'primary' : 'secondary'} >
                        {pp.pasta.descricao}
                    </Badge >
                    {' '}
                </div>
            )
        )

        const idImgCard = `mini-foto-${this.state.pessoa.id}`;

        const { classes } = this.props;

        return (
            <div>

                <Fade>
                    <Card style={{ marginTop: '15px' }} >
                        <CardHeader className="alert-info" style={{ cursor: 'pointer' }} onClick={this.toogleCollapse}>
                            <Row>
                                <Col md="1" >
                                    {
                                        this.state.pessoa.fotoPessoa
                                            ? <Avatar
                                                className={classes.avatar}
                                                id={idImgCard}
                                                alt="Samuel"
                                                src={this.state.pessoa.fotoPessoa.link}
                                                imgProps={{
                                                    'data-ignore-collpase': 'true'
                                                }}
                                            />
                                            : <img
                                                id={idImgCard}
                                                src={logo}
                                                alt="Foto"
                                                width="48px"
                                                height="48px"
                                                style={{ borderRadius: '50%' }}
                                                data-ignore-collpase
                                            />
                                    }
                                    <UncontrolledTooltip
                                        ref={(el) => this.toolTipRef = el}
                                        target={idImgCard}
                                        trigger="click"
                                        placement="auto"
                                    >
                                        {
                                            this.state.pessoa.fotoPessoa &&
                                            <img
                                                src={this.state.pessoa.fotoPessoa.link}
                                                alt="logo"
                                                width="100%"
                                                height="100%"
                                                style={{ marginBottom: '3px' }}
                                            />
                                        }
                                        <div style={{ display: 'flex', justifyContent: 'left' }}>
                                            <Button
                                                disabled={!this.context.isPermiteAlteraPessoa}
                                                color="primary"
                                                size="sm"
                                                data-ignore-collpase
                                                onClick={this.openUploadFoto}
                                            >
                                                {this.state.pessoa.fotoPessoa ? 'Alterar Foto' : 'Incluir Foto'}
                                            </Button>
                                        </div>
                                    </UncontrolledTooltip>
                                </Col>
                                <Col>
                                    <CardTitle tag="h6" style={{ height: '8px', marginTop: '13px', fontSize: '16px' }}>{this.state.pessoa.nome}</CardTitle>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Collapse isOpen={this.state.isOpen}>
                            <CardBody>
                                <Row>
                                    <Col xl="2">
                                        <CardTitle tag="h6">E-mail: </CardTitle>
                                    </Col>
                                    <Col>
                                        <CardText>{this.state.pessoa.email}</CardText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="2">
                                        <CardTitle tag="h6">CPF: </CardTitle>
                                    </Col>
                                    <Col>
                                        <CardText>{formatter.cpf(this.state.pessoa.documentos.cpf)}</CardText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="2">
                                        <CardTitle tag="h6">RG: </CardTitle>
                                    </Col>
                                    <Col>
                                        <CardText>{this.state.pessoa.documentos.rg}</CardText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="2">
                                        <CardTitle tag="h6">Endereço: </CardTitle>
                                    </Col>
                                    <Col>
                                        <CardText>{enderecoCompleto}</CardText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="2">
                                        <CardTitle tag="h6">Telefones: </CardTitle>
                                    </Col>
                                    <Col>
                                        <CardText>{telefones}</CardText>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl="2">
                                        <CardTitle tag="h6">Pastas: </CardTitle>
                                    </Col>
                                    <Col>
                                        <div>
                                            {pastas}
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col md="1">
                                        <Button size="sm" onClick={this.visualizar}>Visualizar</Button>
                                    </Col>
                                    <Col md="1">
                                        <Button disabled={!this.context.isPermiteAlteraPessoa} size="sm" onClick={this.editar}>Editar</Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            size="sm"
                                            style={{ float: 'right' }}
                                            onClick={this.adicionaPessoaPasta}
                                        >
                                            Adicionar Pasta
                                        </Button>
                                    </Col>
                                    <Col>
                                        {
                                            !isObjectEmpty(this.props.pastaSelecionada)
                                            &&
                                            <Button
                                                size="sm"
                                                style={{ float: 'right', backgroundColor: '#E2555B' }}
                                                onClick={this.removerPessoaPasta}
                                            >
                                                Remover desta Pasta
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Collapse>
                    </Card>
                </Fade>

                <Modal isOpen={this.state.isUploadFotoOpen} backdrop={true} keyboard={false} onClosed={this.closedUploadFotoHandler}>
                    <ModalHeader className="alert alert-primary">
                        <div>
                            <h4>Upload Foto</h4>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <CadPessoaUploadFoto servicePessoa={this.context.pessoaService} pessoa={this.state.pessoa} />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={() => this.setState({ isUploadFotoOpen: false })}
                            disabled={this.state.isUploadFotoEmProgresso}
                        >
                            Fechar
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default withStyles(styles)(withRouter(ListaPessoaItem))