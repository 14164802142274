import API from '../lib/api'

export default class PastaService {

    constructor() {
        this.baseURL = 'pasta'
        this.inclui = this.inclui.bind(this)
    }

    async inclui(pasta) {
        const resp = await API.post(this.baseURL, pasta)
        if (resp.status !== 201) {
            throw new Error(`codigo: ${resp.status} \n ${resp.statusText}`)
        }
        return resp.data
    }

    async inativa(pasta) {
        const resp = await API.put(`${this.baseURL}/deleta`, pasta)
        if (resp.status !== 201) {
            throw new Error(`codigo: ${resp.status} \n ${resp.statusText}`)
        }
    }
    
    async pastas() {
        const resp = await API.get(this.baseURL)
        if (resp.status !== 200) {
            throw new Error(`codigo: ${resp.status} \n ${resp.statusText}`)
        }
        return resp.data
    }

    async addPessoaPasta(pastaPessoa, pessoa) {
        const resp = await API.post(`${this.baseURL}/pessoa/${pessoa.id}`, pastaPessoa)
        if (resp.status !== 201) {
            throw new Error(`codigo: ${resp.status} \n ${resp.statusText}`)
        }
        return resp.data
    }

    async removePessoaPasta(pastaPessoa) {
        const resp = await API.delete(`${this.baseURL}/pessoa/${pastaPessoa.id}`)
        if (resp.status !== 200) {
            throw new Error(`codigo: ${resp.status} \n ${resp.statusText}`)
        }
    }
}