import React from 'react'
import { Col, Row, Container, Label, Input } from 'reactstrap'

export default class CadPessoaDocumento extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pessoa: props.pessoa,
        }
    }

    update = (e) => {
        let pessoa = this.state.pessoa
        pessoa.documentos[`${e.target.id}`] = e.target.checked
        this.setState({ pessoa })
    }

    render() {
        return (
            <Container style={{ marginTop: '10px' }}>
                <Row>
                    <Col>
                        <Label check style={{ marginLeft: '21px', marginTop: '10px' }}>
                            <Input id="temCertidaoNascimento" type="checkbox" checked={this.state.pessoa.documentos.temCertidaoNascimento} onChange={this.update} />
                            Certidao de Nascimento
                        </Label>
                    </Col>
                    <Col>
                        <Label check style={{ marginLeft: '21px' , marginTop: '10px' }}>
                            <Input id="temPispasep" type="checkbox" checked={this.state.pessoa.documentos.temPispasep} onChange={this.update} />
                            PIS/PASEP
                        </Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label check style={{ marginLeft: '21px', marginTop: '10px' }}>
                            <Input id="temCtps" type="checkbox" checked={this.state.pessoa.documentos.temCtps} onChange={this.update} />
                            CTPS
                        </Label>
                    </Col>
                    <Col>
                        <Label check style={{ marginLeft: '21px', marginTop: '10px' }}>
                            <Input id="temCertificadoReservista" type="checkbox" checked={this.state.pessoa.documentos.temCertificadoReservista} onChange={this.update} />
                            Certificado de Reservista
                        </Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label check style={{ marginLeft: '21px', marginTop: '10px' }}>
                            <Input id="temCpf" type="checkbox" checked={this.state.pessoa.documentos.temCpf} onChange={this.update} />
                            CPF
                        </Label>
                    </Col>
                    <Col>
                        <Label check style={{ marginLeft: '21px', marginTop: '10px' }}>
                            <Input id="temTituloEleitor" type="checkbox" checked={this.state.pessoa.documentos.temTituloEleitor} onChange={this.update} />
                            Titulo de Eleitor
                        </Label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label check style={{ marginLeft: '21px', marginTop: '10px' }}>
                            <Input id="temCnh" type="checkbox" checked={this.state.pessoa.documentos.temCnh} onChange={this.update} />
                            CNH
                        </Label>
                    </Col>
                    <Col>
                        <Label check style={{ marginLeft: '21px', marginTop: '10px' }}>
                            <Input id="temRg" type="checkbox" checked={this.state.pessoa.documentos.temRg} onChange={this.update} />
                            RG
                        </Label>
                    </Col>
                </Row>
            </Container >
        )
    }
}