import React from 'react'
import { Col, Row, Container, Label, Input, Table, Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FaTrash, FaPlus } from 'react-icons/fa'
import { sleep, isObjectEmpty } from '../../../lib/util'
import { erroColor, sucessoColor, infoColor } from '../../../lib/color'
import ReactLoading from "react-loading";
import ServicoParceiroService from '../../../service/ServicoParceiroService'

import { CadParceiroContext } from './context/CadParceiroContext'

export default class SinteseServicoParceiro extends React.Component {

    static contextType = CadParceiroContext

    constructor(props) {
        super(props)
        this.state = {
            listaServicoParceiro: [],
            servicoParceiroSelecionado: {},
            novoServicoParceiro: {},
            sinteseServicoParceiro: {
                servicoParceiro: {}
            },
            msg: '',
            msgTipo: '',
            msgVisivel: false,
            isCadastrandoServico: false,
            bgModalTitle: infoColor.background,
            fonteModalTitle: infoColor.font,
            erroValidacaoServicoParceiro: false,
            msgValidacaoServicoParceiro: '',
            isCadastrouServicoParceiro: false,
            erroCadastroServicoParceiro: '',
            isCarregando: false,
            msgConfirmacao: {
                alertClass: 'alert alert-warning',
                isOpen: false,
                titulo: '',
                text: '',
                confirmHandler: () => { },
                cancelHandler: () => { },
            }
        }
        this.servicoParceiroService = new ServicoParceiroService()
    }

    async componentDidMount() {
        try {
            const listaServicoParceiro = await this.servicoParceiroService.lista()
            this.setState({ listaServicoParceiro })
        } catch (error) {
            alert(error.response.data.message)
            this.props.history.goBack()
        }
    }

    update = (event) => {

        if (event.target.id === 'parceiroServicoSelecionado') {

            const servicoParceiroSelecionado = this.state.listaServicoParceiro.filter(p => String(p.id) === event.target.value)[0] || {}
            this.setState({ servicoParceiroSelecionado })

        } else if (event.target.id === 'sinteseServicoParceiro') {

            const sinteseServicoParceiro = this.state.sinteseServicoParceiro
            sinteseServicoParceiro.descricao = event.target.value

            this.setState({ sinteseServicoParceiro })

        } else if (event.target.id === 'novoServicoParceiro') {

            const novoServicoParceiro = this.state.novoServicoParceiro
            novoServicoParceiro.descricao = event.target.value

            this.setState({ novoServicoParceiro })
        }

    }

    addSinteseServicoParceiro = () => {

        this.limpaMsg()

        if (!this.state.sinteseServicoParceiro.descricao || !this.state.servicoParceiroSelecionado.descricao) {

            this.setState({
                msg: 'Informe o serviço e a descrição antes de adicionar',
                msgTipo: 'danger',
                msgVisivel: true
            })

            sleep(5000, this.limpaMsg)

            return
        }

        const sinteseServicoParceiro = this.state.sinteseServicoParceiro
        sinteseServicoParceiro.servicoParceiro = this.state.servicoParceiroSelecionado

        this.props.parceiro.listaSinteseServicoParceiro.push(Object.assign({}, sinteseServicoParceiro))

        this.setState({
            servicoParceiroSelecionado: { id: -1 },
            sinteseServicoParceiro: {
                descricao: '',
                servicoParceiro: {}
            }
        })
    }

    removeSinteseServicoParceiro = (index) => {
        this.props.parceiro.listaSinteseServicoParceiro.splice(index, 1)
        this.setState({})
    }

    openModalCadastroServicoParceiro = () => {
        this.setState({
            isCadastrandoServico: true
        })
    }

    closeModalCadastroServicoParceiro = () => {
        this.setState({
            novoServicoParceiro: {},
            isCadastrandoServico: false,
            bgModalTitle: infoColor.background,
            fonteModalTitle: infoColor.font,
            erroValidacaoServicoParceiro: false,
            msgValidacaoServicoParceiro: '',
            isCadastrouServicoParceiro: false,
            erroCadastroServicoParceiro: '',
            isCarregando: false
        })
    }

    cadastrarServicoParceiro = async () => {

        try {

            if (!this.state.novoServicoParceiro.descricao) {
                this.setState({
                    erroValidacaoServicoParceiro: true,
                    msgValidacaoServicoParceiro: 'Informe o nome do serviço antes de confirmar'
                })
                return
            } else {

                for (let index = 0; index < this.state.listaServicoParceiro.length; index++) {
                    const servico = this.state.listaServicoParceiro[index];
                    if (servico.descricao === this.state.novoServicoParceiro.descricao) {
                        this.setState({
                            erroValidacaoServicoParceiro: true,
                            msgValidacaoServicoParceiro: 'Já existe um Serviço cadastrado com essa descrição'
                        })
                        return
                    }
                }
            }

            this.setState({ erroValidacaoServicoParceiro: false, isCarregando: true })

            await sleep(1000, this.servicoParceiroService.inclui, this.state.novoServicoParceiro)

            const listaServicoParceiro = await this.servicoParceiroService.lista()

            this.setState({
                listaServicoParceiro,
                novoServicoParceiro: {},
                isCarregando: false,
                isCadastrouServicoParceiro: true,
                bgModalTitle: sucessoColor.background,
                fonteModalTitle: sucessoColor.font
            })

        } catch (error) {
            this.setState({
                isCarregando: false,
                isCadastrouServicoParceiro: false,
                erroCadastroServicoParceiro: error.response ? error.response.data.error : error.message,
                bgModalTitle: erroColor.background,
                fonteModalTitle: erroColor.font
            })
        }
    }

    deletaServicoParceiro = () => {
        this.setState({
            msgConfirmacao: {
                alertClass: 'alert alert-warning',
                isOpen: true,
                titulo: 'Excluir Serviço',
                text: `Confirmar a exclusão do Serviço ${this.state.servicoParceiroSelecionado.descricao}?`,
                confirmHandler: async () => {
                    try {

                        await this.servicoParceiroService.exclui(this.state.servicoParceiroSelecionado)

                        const listaServicoParceiro = await this.servicoParceiroService.lista()

                        this.setState({
                            msgConfirmacao: { isOpen: false },
                            listaServicoParceiro,
                            servicoParceiroSelecionado: {},
                            msg: `Serviço ${this.state.servicoParceiroSelecionado.descricao} excluído com sucesso`,
                            msgTipo: 'success',
                            msgVisivel: true
                        })

                    } catch (error) {
                        this.setState({
                            msg: error.response ? error.response.data.error : error.message,
                            msgTipo: 'danger',
                            msgVisivel: true
                        })
                    }
                },
                cancelHandler: () => {
                    this.setState({
                        msgConfirmacao: {
                            isOpen: false
                        }
                    })
                }
            }
        })
    }

    limpaMsg = () => {
        this.setState({
            msg: '',
            msgTipo: '',
            msgVisivel: false
        })
    }

    render() {

        const listaServicoParceiroComp = this.state.listaServicoParceiro.map(i => <option key={i.descricao} value={i.id}>{i.descricao}</option>)
        listaServicoParceiroComp.splice(0, 0, <option key={listaServicoParceiroComp} value={-1}>Selecione...</option>)

        const listaSinteseServicoParceiroComp = this.props.parceiro.listaSinteseServicoParceiro.map(
            (s, index) => (
                <tr key={`SinteseServicoParceiro${index}`}>
                    <td>{s.servicoParceiro.descricao}</td>
                    <td><Input type="textarea" value={s.descricao} readOnly /></td>
                    <td>
                        <FaTrash size="0.8em" color="red" style={{ cursor: 'pointer' }} onClick={this.removeSinteseServicoParceiro.bind(this, index)} />
                    </td>
                </tr>
            )
        )

        return (
            <Container>
                <Alert color={this.state.msgTipo} isOpen={this.state.msgVisivel} toggle={() => this.limpaMsg()}>
                    {this.state.msg}
                </Alert>
                <Row>
                    <Col md={5}>
                        <Label for="parceiroServicoSelecionado">Tipo de Serviço</Label>
                        <div style={{ display: 'flex' }}>
                            <Input type="select" id="parceiroServicoSelecionado" value={this.state.servicoParceiroSelecionado.id} onChange={this.update}>
                                {listaServicoParceiroComp}
                            </Input>
                            <Button
                                disabled={!this.context.isPermiteCadastraServicoParceiro}
                                size="sm"
                                style={{ marginLeft: '15px', height: '80%', backgroundColor: '#007BFF' }}
                                onClick={this.openModalCadastroServicoParceiro}
                            >
                                <FaPlus style={{ marginBottom: '3px' }} />
                            </Button>
                            <Button
                                size="sm"
                                style={{ marginLeft: '15px', height: '80%', backgroundColor: '#E2555B' }}
                                onClick={this.deletaServicoParceiro}
                                disabled={!this.context.isPermiteExcluiServicoParceiro || isObjectEmpty(this.state.servicoParceiroSelecionado)}
                            >
                                <FaTrash style={{ marginBottom: '3px' }} />
                            </Button>
                        </div>
                    </Col>
                    <Col>
                        <Label for="sinteseServicoParceiro">Descrição</Label>
                        <Input id="sinteseServicoParceiro" value={this.state.sinteseServicoParceiro.descricao} onChange={this.update} />
                    </Col>
                    <Col md={1}>
                        <Button
                            color="primary"
                            size="sm"
                            style={{ marginTop: '35px' }}
                            onClick={this.addSinteseServicoParceiro}
                        >
                            Adicionar
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginTop: '30px' }} className="mr-auto ml-auto" >
                        <Table>
                            <thead>
                                <tr>
                                    <th style={{ width: '30%' }}>Tipo de Serviço</th>
                                    <th>Descrição</th>
                                    <th style={{ width: '5%' }}>{' '}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listaSinteseServicoParceiroComp}
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Modal
                    isOpen={this.state.isCadastrandoServico}
                    backdrop={true}
                >
                    <ModalHeader style={{ background: this.state.bgModalTitle }}>
                        <div>
                            <h4 style={{ color: this.state.fonteModalTitle }}>Cadastrar novo Serviço Parceiro</h4>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        <Alert color='danger' isOpen={this.state.erroValidacaoServicoParceiro} toggle={() => this.setState({ erroValidacaoServicoParceiro: false })}>
                            {this.state.msgValidacaoServicoParceiro}
                        </Alert>
                        {
                            this.state.isCadastrouServicoParceiro
                                ? (<b>Serviço Cadastrado com sucesso</b>)
                                : this.state.erroCadastroServicoParceiro
                                    ? this.state.erroCadastroServicoParceiro
                                    : (
                                        this.state.isCarregando
                                            ? (<Row>
                                                <Col xs={12} md={3} className="mr-auto ml-auto">
                                                    <ReactLoading type="spokes" color="#0069D9" />
                                                </Col>
                                            </Row>)
                                            : (<div>
                                                <Label>Nome Serviço</Label>
                                                <Input id="novoServicoParceiro" onChange={this.update} />
                                            </div>)
                                    )
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={this.cadastrarServicoParceiro}
                            hidden={this.state.isCadastrouServicoParceiro || this.state.isCarregando || this.state.erroCadastroServicoParceiro}
                        >
                            Confirmar
                        </Button>
                        <Button color="secondary" onClick={(this.closeModalCadastroServicoParceiro)}>Fechar</Button>
                    </ModalFooter>
                </Modal>

                {/* modal confirmacoes */}
                <Modal
                    isOpen={this.state.msgConfirmacao.isOpen}
                    backdrop={true}
                >
                    <ModalHeader className={this.state.msgConfirmacao.alertClass}>
                        <div>
                            <h4>{this.state.msgConfirmacao.titulo}</h4>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        {this.state.msgConfirmacao.text}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.state.msgConfirmacao.confirmHandler}>Confirmar</Button>
                        <Button color="secondary" onClick={this.state.msgConfirmacao.cancelHandler}>Fechar</Button>
                    </ModalFooter>
                </Modal>

            </Container >
        )
    }
}