import React from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { FaFilter, FaPlus } from 'react-icons/fa'
import ResourceService from '../../../service/ResourceService'
import FiltroPesquisaFields from './FiltroPesquisaFields'

import sessionStore from '../../../lib/sessionStore'

export default class FiltroPesquisa extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            filtroPesquisa: this.props.filtroPesquisa,
            fieldsPesquisaPessoa: []
        }
        this.cont = 0
        this.keyMapCompareConstraints = {}
        this.resourceService = new ResourceService()
    }

    async componentDidMount() {

        const fieldsPesquisaPessoa = await this.resourceService.fieldsPesquisaPessoa()

        let keysTipoField = fieldsPesquisaPessoa.map(fpp => fpp.tipoField)
        keysTipoField = [...new Set(keysTipoField)] // to have distinct keys

        for (const key of keysTipoField) {
            this.keyMapCompareConstraints[`${key}`] = await this.resourceService.compareConstraints(key)
        }

        const filtroPesquisaSession = sessionStore.getFiltroPesquisa()

        this.setState({
            fieldsPesquisaPessoa,
            filtroPesquisa: filtroPesquisaSession ? filtroPesquisaSession : this.state.filtroPesquisa
        })
        
        if(filtroPesquisaSession) {
            this.cont = filtroPesquisaSession.filtros.length
            this.props.setFiltroPesquisaParent(filtroPesquisaSession)
        }
    }

    addFiltro = () => {
        const filtroPesquisa = this.state.filtroPesquisa
        const filtro = {
            id: ++this.cont,
            comparator: {
                nome: '',
                classe: ''
            }
        }
        filtroPesquisa.filtros.push(filtro)
        this.setState({ filtroPesquisa })
    }

    removeFiltro(index) {
        const filtroPesquisa = this.state.filtroPesquisa
        const filtros = filtroPesquisa.filtros.filter((item, i) => index !== i)
        filtroPesquisa.filtros = filtros
        this.setState(
            { filtroPesquisa },
            this.props.whenFilterRemovedHandler
        )
    }

    filtrar = () => {
        this.props.pesquisaHandler(true)
    }

    render() {

        const filtrosComponent = this.state.filtroPesquisa.filtros.map((f, index) =>
            <FiltroPesquisaFields
                key={f.id}
                filtroPesquisa={this.state.filtroPesquisa}
                filtro={f}
                fieldsPesquisaPessoa={this.state.fieldsPesquisaPessoa}
                keyMapCompareConstraints={this.keyMapCompareConstraints}
                index={index}
                removeFiltroHandler={this.removeFiltro.bind(this, index)}
            />
        )

        return (
            <Container style={{
                border: '2px',
                borderStyle: 'solid',
                borderRadius: '6px',
                borderColor: '#E7E9EC',
                paddingTop: '15px',
                paddingBottom: '15px',
                marginTop: '30px',
                backgroundColor: '#FBFBFB'
            }}>
                {filtrosComponent}
                <Row>
                    <Col>
                        <Button
                            size="sm"
                            color="secondary"
                            className="btn-sm"
                            onClick={this.addFiltro}
                        >
                            Novo Filtro
                            <FaPlus style={{ marginBottom: '3px', marginLeft: '3px' }} />
                        </Button>
                    </Col>
                    <Col>
                        {
                            this.state.filtroPesquisa.filtros.length > 0 &&
                            <Button
                                size="sm"
                                color="primary"
                                className="btn-sm"
                                style={{ display: 'block', float: 'right' }}
                                onClick={this.filtrar}
                            >
                                Filtrar
                                <FaFilter style={{ marginBottom: '3px', marginLeft: '3px' }} />
                            </Button>
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}