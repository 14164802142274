import React from 'react'
import {
    Alert,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap'
import FileDownload from 'js-file-download'
import { FaFileUpload } from 'react-icons/fa'
import ReactLoading from "react-loading"
import ParceiroService from '../../../service/ParceiroService'
import ListaArquivo from './ListaArquivo'

export default class DialogGerenciarArquivo extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            msg: {
                text: '',
                tipo: '',
                visivel: false
            }
        }
        this.inputFileRef = null
        this.parceiroService = new ParceiroService()
    }

    uploadArquivo = () => {
        this.inputFileRef.click()
    }

    fileSelectionHandler = async (e) => {
        if (e.target.files) {
            try {
                this.setState({ isLoading: true })
                const arquivoParceiro = await this.parceiroService.uploadArquivo(this.props.parceiro.id, e.target.files[0])
                this.props.parceiro.listaArquivoParceiro.push(arquivoParceiro)
            } catch (error) {
                this.setState({
                    msg: {
                        tipo: 'danger',
                        text: error.response ? error.response.data.error : error.message,
                        visivel: true
                    }
                })
            } finally {
                e.target.value = ''
                this.setState({ isLoading: false })
            }
        }
    }

    downloadArquivo = async (idParceiro, idArquivo, nomeArquivo, cb) => {
        try {
            const data = await this.parceiroService.downloadArquivo(idParceiro, idArquivo)
            FileDownload(data, nomeArquivo)
            cb()
        } catch (error) {
            this.setState({
                msg: {
                    tipo: 'danger',
                    text: error.response ? error.response.data.error : error.message,
                    visivel: true
                }
            })
            cb(error)
        }
    }

    deletaArquivo = async (idParceiro, idArquivo, nomeArquivo) => {
        try {
            await this.parceiroService.deleteArquivo(idParceiro, idArquivo)
            this.removeArquivoById(idArquivo)
            this.setState({
                msg: {
                    tipo: 'success',
                    text: `O arquivo [${nomeArquivo}] foi excluído com sucesso`,
                    visivel: true
                }
            })
        } catch (error) {
            this.setState({
                msg: {
                    tipo: 'danger',
                    text: error.response ? error.response.data.error : error.message,
                    visivel: true
                }
            })
        }
    }

    removeArquivoById = (id) => {
        const index = this.props.parceiro.listaArquivoParceiro.findIndex((a) => a.idArquivo === id)
        this.props.parceiro.listaArquivoParceiro.splice(index, 1)
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                backdrop={true}
                size="lg"
                scrollable={true}
            >
                <ModalHeader>
                    <div>
                        <h4>Arquivos Parceiro: {this.props.parceiro.nome}</h4>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Alert
                        color={this.state.msg.tipo}
                        isOpen={this.state.msg.visivel}
                        children={this.state.msg.text}
                        toggle={() => this.setState({ msg: { visivel: false } })}
                    />
                    {
                        this.state.isLoading
                            ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <span>Efetuando Upload </span>
                                <ReactLoading height="30px" width="30px" type="bubbles" color="#0069D9" />
                            </div>
                            : <ListaArquivo
                                parceiro={this.props.parceiro}
                                arquivos={this.props.parceiro.listaArquivoParceiro}
                                downloadHandler={this.downloadArquivo}
                                deleteHandler={this.deletaArquivo}
                            />
                    }
                </ModalBody>
                <ModalFooter >
                    <Button color="primary" style={{ marginRight: '30px' }} onClick={this.uploadArquivo} >
                        Upload Arquivo (Max: 10 MB)
                        <FaFileUpload style={{ marginBottom: '3px', marginLeft: '5px' }} />
                    </Button>
                    <input
                        type="file"
                        id="arquivo"
                        hidden={true}
                        onChange={this.fileSelectionHandler}
                        ref={(input) => this.inputFileRef = input}
                    />
                    <Button color="secondary" onClick={this.props.toggle}>Fechar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}