import React from 'react'
import { Col, Row, Container, Label, Input } from 'reactstrap'

export default class ConfirmarParceiro extends React.Component {

    render() {

        return (
            <Container>
                <Row>
                    <Col md={6}>
                        <Label for="nome">Representante Legal</Label>
                        <Input
                            id="nome"
                            data-classe="representanteLegal"
                            value={this.props.parceiro.representanteLegal.nome}
                            onChange={this.props.updateHandler}
                        />
                    </Col>

                    <Col >
                        <Label for="dataInicioMandato">Data Inicio Mandato</Label>
                        <Input
                            type="date"
                            id="dataInicioMandato"
                            data-classe="representanteLegal"
                            value={this.props.parceiro.representanteLegal.dataInicioMandato}
                            onChange={this.props.updateHandler}
                        />
                    </Col>

                    <Col >
                        <Label for="dataTerminoMandato">Data Final Mandato</Label>
                        <Input
                            type="date"
                            id="dataTerminoMandato"
                            data-classe="representanteLegal"
                            value={this.props.parceiro.representanteLegal.dataTerminoMandato}
                            onChange={this.props.updateHandler}
                        />
                    </Col>
                </Row>
            </Container >
        )
    }
}