import React from 'react'
import { Container, Row, Col, Label, Input, Button, Alert } from 'reactstrap'
import ReactLoading from 'react-loading'
import { sleep } from '../../../lib/util'

export default class CadPessoaUploadFotoFile extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            file: null,
            msgVisible: false,
            msgText: '',
            msgType: '',
            loading: false
        }
    }

    update = (e) => {
        this.setState({
            file: e.target.files[0],
            msgVisible: false
        });
    }

    exibeMsg = (text, type) => {
        this.setState({ msgVisible: true, msgText: text, msgType: type, loading: false })
    }

    enviaFoto = async () => {

        if (this.state.file) {
            try {
                const { servicePessoa, pessoa } = this.props
                this.setState({ loading: true })
                const fotoPessoa = await sleep(
                    100,
                    servicePessoa.uploadFoto,
                    pessoa.id,
                    this.state.file
                )
                pessoa.fotoPessoa = fotoPessoa
                this.exibeMsg('Foto enviada com sucesso', 'success')
            } catch (error) {
                this.exibeMsg(error.response ? error.response.data.message : error.message, 'danger')
            }
        } else {
            this.exibeMsg('Selecione um arquivo', 'danger')
        }
    }

    render() {
        return (
            <Container>
                {
                    this.state.loading
                        ? <Row>
                            <Col xs={12} md={3} className="mr-auto ml-auto" style={{ marginTop: '20px' }}>
                                <ReactLoading type="spokes" color="#0069D9" />
                            </Col>
                        </Row>
                        : <Alert
                            color={this.state.msgType}
                            isOpen={this.state.msgVisible}
                            toggle={() => this.setState({ msgVisible: false })}
                            style={{ marginTop: '20px' }}
                        >
                            {this.state.msgText}
                        </Alert>
                }
                <Row>
                    <Col sm="12" >
                        <Label for="foto" style={{ marginTop: '20px' }}>Foto</Label>
                        <Input type="file" id="foto" onChange={this.update} />
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" >
                        <Button color="primary" size="sm" style={{ marginTop: '20px' }} onClick={this.enviaFoto} >
                            Enviar Foto
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }
}