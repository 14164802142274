import React from 'react'
import { withRouter } from 'react-router-dom'
import { Container } from 'reactstrap'
import PessoaService from '../../../service/PessoaService'

import ListaAssinaturaItem from './ListaAssinaturaItem'

class ListaAssinatura extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pessoas: [],
            titulo: ''
        }
    }

    async componentDidMount() {

        try {

            const propsAsString = window.atob(this.props.match.params.props)
            const props = JSON.parse(propsAsString)

            const { pastaSelecionada, filtroPesquisa, pagination, titulo } = props

            const pessoaService = new PessoaService();

            const promises = []
            for (let i = 0; i < pagination.total; i++) {

                let promise = pessoaService.lista(
                    pastaSelecionada,
                    filtroPesquisa,
                    {
                        page: i,
                        size: pagination.size
                    }
                )

                promises.push(promise)
            }


            const pessoas = []
            for await (const result of promises) {
                pessoas.push(...result.content)
            }

            this.setState({
                pessoas,
                titulo: titulo ? titulo : pastaSelecionada.descricao
            })

        } catch (error) {
            if (error.response) {
                alert(error.response.data.error)
            } else {
                alert(error.message)
            }
            this.props.history.goBack()
        }
    }

    render() {

        const pessoaAssinaturaComp = this.state.pessoas.map((p, index) => <ListaAssinaturaItem pessoa={p} zebra={(index % 2) === 0} />)
        const data = new Intl.DateTimeFormat("pt-BR").format(new Date())
        return (
            <Container style={{ marginTop: '30px' }}>
                <h1 style={{ marginBottom: '20px' }}>{this.state.titulo}</h1>
                <p style={{ marginBottom: '15px', display: 'block', textAlign: 'right' }}>Data: {data}</p>
                {pessoaAssinaturaComp}
            </Container>
        )
    }
}

export default withRouter(ListaAssinatura)