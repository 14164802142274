import React from 'react'
import axios from 'axios'
import authDataFromStore from './authDataFromStore'
import localStore from './localStore'
import sessionStore from './sessionStore'
import isDev from 'react-is-dev'

let authData = authDataFromStore()
let authHeader = {}
if (authData) {
  authHeader = { 'Authorization': `${authData.type} ${authData.token}`, 'Access-Control-Allow-Origin': '*' }
}

const API = axios.create({
  baseURL: 'https://api.dhrdp.com.br:8443/riodepaz-api/',
  // baseURL: 'https://104.248.50.12:8443/riodepaz-api/',
  // baseURL: `${isDev(React) ? 'http://localhost:8080' : 'http://localhost:8080'}/riodepaz-api/`,
  headers: authHeader
})

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data.message === 'TOKEN_EXPIRED') {
      localStore.clear()
      sessionStore.clear()
      error.response.data.message = 'Sessão expirada'
      window.location.href = `/login/${window.btoa('Sessão expirada')}`
    }
    return Promise.reject(error)
  }
)

export default API

export const ViaCepAPI = axios.create({ baseURL: 'https://viacep.com.br/ws' })