import React from 'react'
import { withRouter } from 'react-router-dom'
import { Container } from 'reactstrap'
import ParceiroService from '../../../service/ParceiroService'
import { FaWhatsapp } from 'react-icons/fa'
import { formatter } from '../../../lib/util'

class ViewParceiro extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parceiro: {
                endereco: {},
                telefones: [],
                contatoTecnico: {
                    telefone: {}
                },
                informacoesParceiro: {},
                representanteLegal: {},
                listaSinteseServicoParceiro: []
            }
        }
        this.parceiroService = new ParceiroService()
    }

    async componentDidMount() {
        const ecodedId = this.props.match.params.id
        if (ecodedId) {
            try {
                const id = window.atob(ecodedId)
                const parceiro = await this.parceiroService.getById(id)
                console.log(parceiro.dataInscricaoCnpj)
                this.setState({ parceiro })
            } catch (error) {
                alert(error.response ? error.response.data.error : error.message)
                this.props.history.goBack()
            }
        }
    }

    render() {


        const length = this.state.parceiro.telefones.length
        const telefones = this.state.parceiro.telefones.map(
            (tel, index) =>
            (
                <div style={{ display: 'inline' }}>
                    {formatter.telefone(tel.ddd, tel.numero)}
                    {' '}
                    {tel.whatsapp && <FaWhatsapp style={{ marginBottom: '5px' }} color="#2DB843" />}
                    {(length > 0 && index !== (length - 1)) && ', '}
                </div>
            )
        )

        const sinteseServicos = this.state.parceiro.listaSinteseServicoParceiro.map(
            (sintese) =>
            (
                <p >
                    <span>{sintese.servicoParceiro.descricao}</span>
                    {': '}
                    {sintese.descricao}
                </p>
            )
        )

        return (
            <Container style={{ marginTop: '30px' }}>
                <div id="conteudo" className="item">
                    <h2 style={{ marginBottom: '20px' }}>Identificação:</h2>
                    <p><span>Nome da Instituição ou Entidade: </span>{this.state.parceiro.nome}</p>
                    <p>
                        <span>CNPJ: </span>{formatter.cnpj(this.state.parceiro.cnpj)}
                        <span className="span-item">Data Inscrição CNPJ: </span>{formatter.data(this.state.parceiro.dataInscricaoCnpj)}
                    </p>
                    <p>
                        <span>E-mail: </span>{this.state.parceiro.email}
                        <span className="span-item">CNAE: </span>{this.state.parceiro.cnaePrincipal}
                        <span className="span-item">CNAE Secuncário: </span>{this.state.parceiro.cnaeSecundario}
                    </p>
                    <p>
                        <span>Finalidade Estatutaria: </span>{this.state.parceiro.finalidadeStatutaria}
                    </p>
                    <p>
                        <span>Origem de Recursos: </span>{this.state.parceiro.origemRecursos}
                    </p>
                    <p>
                        <span>Objetivos: </span>{this.state.parceiro.objetivos}
                    </p>
                    <p>
                        <span>Info. Complementar: </span>{this.state.parceiro.infoComplementar}
                    </p>
                    <p>
                        <input type="checkbox" checked={this.state.parceiro.setor1} />
                        <label style={{ marginLeft: '10px' }}>1º Setor</label>
                        <input style={{ marginLeft: '70px' }} type="checkbox" checked={this.state.parceiro.setor2} />
                        <label style={{ marginLeft: '10px' }}>2º Setor</label>
                        <input style={{ marginLeft: '70px' }} type="checkbox" checked={this.state.parceiro.setor3} />
                        <label style={{ marginLeft: '10px' }}>3º Setor</label>
                    </p>
                    <p style={{ marginTop: '30px' }}>
                        <span>Endereço: </span>{this.state.parceiro.endereco && this.state.parceiro.endereco.logradouro}
                        <span className="span-item">Nº: </span>{this.state.parceiro.endereco && this.state.parceiro.endereco.numero}
                        <span className="span-item">Compl: </span>{this.state.parceiro.endereco && this.state.parceiro.endereco.complemento}
                    </p>
                    <p>
                        <span>Bairro: </span>{this.state.parceiro.endereco && this.state.parceiro.endereco.bairro}
                        <span className="span-item">Referência: </span>{this.state.parceiro.endereco && this.state.parceiro.endereco.pontoReferencia}
                        <span className="span-item">CEP: </span>{this.state.parceiro.endereco && formatter.cep(this.state.parceiro.endereco.cep)}
                    </p>
                    <p style={{ marginTop: '30px' }}><span>Telefone(s): </span>{telefones}</p>
                    <h2 style={{ marginTop: '30px', marginBottom: '20px' }}>Contato Técnico:</h2>
                    <p>
                        <span>Nome: </span>{this.state.parceiro.contatoTecnico && this.state.parceiro.contatoTecnico.nome}
                        <span className="span-item">Formação: </span>{this.state.parceiro.contatoTecnico && this.state.parceiro.contatoTecnico.formacao}
                    </p>
                    <p>
                        <span>Telefone: </span>{
                            this.state.parceiro.contatoTecnico &&
                            formatter.telefone(this.state.parceiro.contatoTecnico.telefone.ddd, this.state.parceiro.contatoTecnico.telefone.numero)
                        }
                    </p>
                    <h2 style={{ marginTop: '30px', marginBottom: '20px' }}>Sintese dos Serviços:</h2>
                    {sinteseServicos}
                    <h2 style={{ marginTop: '30px', marginBottom: '20px' }}>Informações:</h2>
                    <p><span>Publico Alvo: </span>{this.state.parceiro.informacoesParceiro && this.state.parceiro.informacoesParceiro.publicoAlvo}</p>
                    <p><span>Abrangencia Territorial: </span>{this.state.parceiro.informacoesParceiro && this.state.parceiro.informacoesParceiro.abrangenciaTerritorial}</p>
                    <p><span>Recursos Humanos Envolvidos: </span>{this.state.parceiro.informacoesParceiro && this.state.parceiro.informacoesParceiro.recursosHumanosEnvolvidos}</p>
                    <p><span>Capacidade de Atendimento: </span>{this.state.parceiro.informacoesParceiro && this.state.parceiro.informacoesParceiro.capacidadeAtendimento}</p>
                    <p><span>Info. Complementar: </span>{this.state.parceiro.informacoesParceiro && this.state.parceiro.informacoesParceiro.infoComplementar}</p>
                    <p><span>Avaliação Tecnico: </span>{this.state.parceiro.informacoesParceiro && this.state.parceiro.avaliacaoTecnicoVisitaInstitucional}</p>
                    <p><span>Avaliação Coordenador: </span>{this.state.parceiro.informacoesParceiro && this.state.parceiro.avaliacaoCoordenador}</p>
                    <h2 style={{ marginTop: '30px', marginBottom: '20px' }}>Representante Legal:</h2>
                    <p>
                        <span>Nome: </span>{this.state.parceiro.representanteLegal && this.state.parceiro.representanteLegal.nome}
                    </p>
                    <p>
                        <span>Data Inicio Mandato: </span>{
                            this.state.parceiro.representanteLegal &&
                            formatter.data(this.state.parceiro.representanteLegal.dataInicioMandato)
                        }
                        <span className="span-item">Data Final Mandato: </span>{
                            this.state.parceiro.representanteLegal &&
                            formatter.data(this.state.parceiro.representanteLegal.dataTerminoMandato)
                        }
                    </p>
                </div>
            </Container>
        )
    }
}

export default withRouter(ViewParceiro)