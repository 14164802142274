import React from 'react'
import {
    Card,
    CardHeader,
    CardTitle,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap'
import ReactLoading from "react-loading"
import { FaFileDownload, FaTrash } from 'react-icons/fa'

export default class ListaArquivoItem extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            downloading: false,
            downloadIconColor: '',
            isMsgExclusaoOpen: false
        }
    }

    download = async () => {
        this.setState({ downloading: true })
        this.props.downloadHandler(
            this.props.parceiro.id,
            this.props.arquivo.idArquivo,
            this.props.arquivo.nome,
            this.downloadConcluido
        )
    }

    downloadConcluido = (error) => {
        this.setState({
            downloading: false,
            downloadIconColor: error ? '#DC3545' : '#28A745',
        })
    }

    excluirArquivo = () => {
        this.props.deleteHandler(
            this.props.parceiro.id,
            this.props.arquivo.idArquivo,
            this.props.arquivo.nome
        )
    }

    render() {
        return (
            <div>
                <Card style={{ marginTop: '15px' }} >
                    <CardHeader className="alert-info">
                        <Row>
                            <Col>
                                <CardTitle tag="h6" style={{ height: '8px', fontSize: '16px' }}>{this.props.arquivo.nome}</CardTitle>
                            </Col>
                            <Col>
                                <div style={{ float: 'right' }}>
                                    {
                                        this.state.downloading
                                            ? <ReactLoading height="17px" width="17px" type="spin" color="#0069D9" />
                                            : <FaFileDownload
                                                color={this.state.downloadIconColor}
                                                style={{ marginBottom: '3px', cursor: 'pointer' }}
                                                onClick={this.download}
                                            />
                                    }
                                    <FaTrash
                                        style={{ marginBottom: '3px', marginLeft: '30px', cursor: 'pointer' }}
                                        onClick={() => this.setState({ isMsgExclusaoOpen: true })}
                                        hidden={this.state.downloading}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                </Card>
                <Modal
                    isOpen={this.state.isMsgExclusaoOpen}
                    backdrop={true}
                >
                    <ModalHeader className="alert alert-warning">
                        <div>
                            <h4>Confirmação de exclusão</h4>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        Confirma a exclusão do arquivo <span>{this.props.arquivo.nome}</span>?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.excluirArquivo}>Confirmar</Button>
                        <Button color="secondary" onClick={() => this.setState({ isMsgExclusaoOpen: false })}>Fechar</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}