class ColorType {
    constructor(background, font) {
        this.background = background
        this.font = font
    }
}

const erroColor = new ColorType('#F8D7DA', '#721C24')
const sucessoColor = new ColorType('#D4EDDA', '#155724')
const infoColor = new ColorType('#CCE5FF', '#004085')

export {
    erroColor,
    sucessoColor,
    infoColor
}