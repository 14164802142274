import React from 'react'
import ListaPessoaItem from './ListaPessoaItem'
import ListaPessoaPagination from './ListaPessoaPagination'

export default class ListaPessoa extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            hasScrollBar: false
        }
    }

    componentDidMount() {
        document.addEventListener('scroll', (e) => {
            this.setState({ hasScrollBar: window.scrollY })
        });
    }

    render() {

        const pessoasComponent = this.props.pessoas.map(
            p =>
                <ListaPessoaItem
                    key={p.id}
                    pessoa={p}
                    pastaSelecionada={this.props.pastaSelecionada}
                    encaminharEdicaoHandler={this.props.encaminharEdicaoHandler}
                    removePessoaPastaHandler={this.props.removePessoaPastaHandler}
                    adicionaPessoaPastaHandler={this.props.adicionaPessoaPastaHandler}
                />
        )

        return (
            <div>
                <ListaPessoaPagination
                    style={{ marginTop: '15px' }}
                    visivel={this.props.pessoas.length > 0}
                    pagination={this.props.pagination}
                    paginationHandler={this.props.paginationHandler}
                />
                <div style={{ marginTop: '15px' }}>
                    {pessoasComponent}
                </div>
                <ListaPessoaPagination
                    style={{ marginTop: '15px' }}
                    visivel={this.props.pessoas.length > 0 && this.state.hasScrollBar}
                    pagination={this.props.pagination}
                    paginationHandler={this.props.paginationHandler}
                />
            </div>
        )
    }
}