import React from 'react'
import localStore from '../../lib/localStore'
import sessionStore from '../../lib/sessionStore'
import authDataFromStore from '../../lib/authDataFromStore'
import { AuthContext } from './context/AuthContext'

export default class ProvideAuth extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            auth: {
                data: authDataFromStore(),
                login: (data, isMantemLogado) => {

                    const auth = this.state.auth
                    auth.data = data

                    if (isMantemLogado) {
                        localStore.setAuth(data)
                    } else {
                        sessionStore.setAuth(data)
                    }

                    window.location.href = '/';

                },
                logout: () => {

                    localStore.clear()
                    sessionStore.clear()

                    window.location.reload();
                },
                isLogado() {
                    return this.data ? true : false
                }
            }
        }
        this.props.setLogout(this.state.auth.logout)
        this.props.setUsuarioLogado(this.state.auth.data ? this.state.auth.data.username : '')
    }

    componentDidMount() {
        if (this.state.auth.isLogado()) {
            this.props.showNavBarHandler()
        }
        this.definePermissoes()
    }

    definePermissoes = () => {
        if (this.state.auth.data) {


            const isPermiteGerenciarUsuarios = this.hasPermissao('GERENCIAR_USUARIO')
            const isPermiteGerarBackup = this.hasPermissao('GERENCIAR_BACKUP')

            this.props.setPermissoesMenu(
                isPermiteGerenciarUsuarios,
                isPermiteGerarBackup
            )
        }
    }


    hasPermissao = (roleName) => {
        const roles = this.state.auth.data.roles
        const isPermitido = roles
            .findIndex(role => role === roleName) > -1
        return isPermitido
    }

    render() {
        return (
            <AuthContext.Provider value={this.state.auth} >
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}