import React from 'react'
import { Col, Row, Container, Label, Input, Table, Button, Alert } from 'reactstrap'
import { FaTrash } from 'react-icons/fa'
import { isObjectEmpty, sleep } from '../../../lib/util'
import PastaService from '../../../service/PastaService'

export default class CadPessoaConfirmar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pessoa: props.pessoa,
            pastaSelecionada: {},
            msg: '',
            msgTipo: '',
            msgVisivel: false,
            pastas: []
        }
        this.pastaService = new PastaService()
    }

    async componentDidMount() {
        const pastas = await this.pastaService.pastas()
        this.setState({ pastas })
    }

    update = (e) => {

        if (e.target.id === 'pasta') {

            const idPasta = e.target.value
            const pastaSelecionada = this.state.pastas.filter(p => String(p.id) === idPasta)[0] || {}
            this.setState({ pastaSelecionada })

        } else {

            let pessoa = this.state.pessoa
            pessoa[`${e.target.id}`] = e.target.value
            this.setState({ pessoa })
        }

    }

    addPasta = () => {

        this.limpaMsg()

        let erro
        let erroMsg
        if (isObjectEmpty(this.state.pastaSelecionada)) {
            erro = true
            erroMsg = 'Selecione uma pasta antes adicionar'
        } else {

            let pastas = this.state.pessoa.listaPastaPessoa.map((pastaPessoa) => pastaPessoa.pasta)
            for (const pasta of pastas) {

                if (pasta.descricao === this.state.pastaSelecionada.descricao) {
                    erro = true
                    erroMsg = 'Pasta ja inclusa na lista'
                    break
                }
            }
        }

        if (erro) {
            this.setState({
                msg: erroMsg,
                msgTipo: 'danger',
                msgVisivel: true
            })
            sleep(5000, this.limpaMsg)
            return
        }

        let pessoa = this.state.pessoa
        pessoa.listaPastaPessoa.push({
            pasta: this.state.pastaSelecionada
        })

        this.setState({ pessoa })
    }

    removePasta = (index) => {

        let pessoa = this.state.pessoa
        pessoa.listaPastaPessoa.splice(index, 1)

        this.setState({ pessoa })
    }

    limpaMsg = () => {
        this.setState({
            msg: '',
            msgTipo: '',
            msgVisivel: false
        })
    }

    render() {

        const pastasComp = this.state.pastas.map(p => <option key={p.id} value={p.id}>{p.descricao}</option>)
        pastasComp.splice(0, 0, <option key={-1} value={-1}>Selecione uma Pasta</option>)

        const pastas = this.state.pessoa.listaPastaPessoa.map((item, index) => {
            return (<tr key={index}>
                <td>{item.pasta.descricao}</td>
                <td><FaTrash id={index} size="0.8em" color="red" onClick={this.removePasta.bind(this, index)} /></td>
            </tr>)
        })

        return (
            <Container>
                <Alert color={this.state.msgTipo} isOpen={this.state.msgVisivel} toggle={() => this.limpaMsg()}>
                    {this.state.msg}
                </Alert>
                <Row>
                    <Col>
                        <Label for="observacoes">Observações</Label>
                        <Input type="textarea" id="observacoes" className="input" value={this.state.pessoa.observacoes} onChange={this.update} maxLength="255" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>Selecione uma pasta e clique em adicionar</Label>
                        <Input type="select" id="pasta" className="input" onChange={this.update}>
                            {pastasComp}
                        </Input>
                    </Col>
                    <Col>
                        <Button color="primary" size="sm" style={{ marginTop: '35px' }} onClick={this.addPasta} >
                            Adicionar na pasta
                        </Button>
                    </Col>
                    <Col>
                        <Table striped style={{ marginTop: '20px' }} >
                            <thead>
                                <tr>
                                    <th>Pastas</th>
                                    <th>{' '}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pastas}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        )
    }
}