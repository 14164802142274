const sessionKeyFiltro = '@rdp/filtros'
const sessionKeyPasta = '@rdp/pasta'
const sessionKeyPaginationConfigPessoa = '@rdp/pagecfgpessoa'
const sessionKeySearchQueryParceiro = '@rdp/searchqueryparceiro'
const localKeyAuth = '@rdp/auth'

const sessionStore = {

    setFiltroPesquisa(filtroPesquisa) {
        sessionStorage.setItem(sessionKeyFiltro, JSON.stringify(filtroPesquisa))
    },

    getFiltroPesquisa() {
        const filtroPesquisaString = sessionStorage.getItem(sessionKeyFiltro)
        const filtroPesquisa = JSON.parse(filtroPesquisaString)
        return filtroPesquisa
    },

    setPasta(pastaSelecionada) {
        sessionStorage.setItem(sessionKeyPasta, JSON.stringify(pastaSelecionada))
    },

    getPasta() {
        const pastaString = sessionStorage.getItem(sessionKeyPasta)
        const pastaSelecionada = JSON.parse(pastaString)
        return pastaSelecionada
    },

    setPaginationConfigPessoa(paginationConfig) {
        sessionStorage.setItem(sessionKeyPaginationConfigPessoa, JSON.stringify(paginationConfig))
    },

    getPaginationConfigPessoa() {
        const paginationConfigString = sessionStorage.getItem(sessionKeyPaginationConfigPessoa)
        const paginationConfig = JSON.parse(paginationConfigString)
        return paginationConfig
    },

    removePaginationConfigPessoa() {
        sessionStorage.removeItem(sessionKeyPaginationConfigPessoa);
    },

    setSearchQueryParceiro(searchQuery) {
        sessionStorage.setItem(sessionKeySearchQueryParceiro, searchQuery)
    },

    getSearchQueryParceiro() {
        const searchQuery = sessionStorage.getItem(sessionKeySearchQueryParceiro)
        return searchQuery
    },
    
    setAuth(auth) {
        sessionStorage.setItem(localKeyAuth, JSON.stringify(auth))
    },

    getAuth() {
        const authAsString = sessionStorage.getItem(localKeyAuth)
        const auth = JSON.parse(authAsString)
        return auth
    },

    clear() {
        sessionStorage.clear()
    }
}

export default sessionStore;