import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from './context/AuthContext'

export default class PrivateRoute extends React.Component {

    render() {

        return (
            <AuthContext.Consumer>
                {
                    (auth) => (
                        <Route
                            exact={this.props.exact}
                            path={this.props.path}
                            render={() =>
                                auth.isLogado()
                                    ? (this.props.children)
                                    : <Redirect to="/login" />
                            }
                        />
                    )
                }

            </AuthContext.Consumer>
        )
    }
}