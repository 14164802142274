import React from 'react'
import UsuarioListaItem from './UsuarioListaItem'
import { GerenciarUsuarioContext } from './context/GerenciarUsuarioContext'

export default class UsuarioLista extends React.Component {

    static contextType = GerenciarUsuarioContext

    render() {

        const usuariosComponent = this.context.usuarios.map(
            u => <UsuarioListaItem key={u.id} usuario={u} />
        )

        return (
            <div style={{ marginTop: '15px' }}>
                {usuariosComponent}
            </div>
        )
    }
}