import React from 'react'
import { Col, Row, Container, Label, Input } from 'reactstrap'

export default class CadPessoaProfissional extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pessoa: props.pessoa,
            itensGrauInstrucao: [],
        }
    }

    async componentDidMount() {
        try {
            const itensGrauInstrucao = await this.props.serviceResource.escolaridade()
            this.setState({ itensGrauInstrucao })
        } catch (error) {
            alert(error.response.data.error)
        }
    }

    update = (e) => {

        let pessoa = this.state.pessoa

        switch (e.target.id) {
            case 'formacaoProfissional':
            case 'desejaProfissionalizar':
                pessoa.informacoes.profissional[`${e.target.id}`] = e.target.value
                break
            case 'grauDeInstrucao':
                if (!pessoa.informacoes[`${e.target.id}`]) {
                    pessoa.informacoes[`${e.target.id}`] = {}
                }
                pessoa.informacoes[`${e.target.id}`].nome = e.target.value
                pessoa.informacoes[`${e.target.id}`].classe = e.target.options[e.target.selectedIndex].getAttribute('data-classe')
                break
            default:
                pessoa.informacoes[`${e.target.id}`] = e.target.value
        }

        this.setState({ pessoa })
    }

    render() {

        const grauInstrucaoComponent = this.state.itensGrauInstrucao.map(i => <option key={i.nome} value={i.nome} data-classe={i.classe}>{i.descricao}</option>)
        grauInstrucaoComponent.splice(0, 0, <option key={grauInstrucaoComponent} value="">Selecione...</option>)

        return (
            <Container>
                <Row>
                    <Col>
                        <Label for="formacaoProfissional">Formação Profissional</Label>
                        <Input id="formacaoProfissional" className="input" value={this.state.pessoa.informacoes.profissional.formacaoProfissional} onChange={this.update} />
                    </Col>
                    <Col>
                        <Label for="desejaProfissionalizar">Deseja se Profissionalizar em qual Profissão</Label>
                        <Input id="desejaProfissionalizar" className="input" value={this.state.pessoa.informacoes.profissional.desejaProfissionalizar} onChange={this.update} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label for="estaTrabalhando">Esta Trabalhando?</Label>
                        <Input type="select" id="estaTrabalhando" className="input" value={this.state.pessoa.informacoes.estaTrabalhando} onChange={this.update}>
                            <option value="">Selecione...</option>
                            <option value="true">Sim</option>
                            <option value="false">Não</option>
                        </Input>
                    </Col>
                    <Col>
                        <Label for="carteiraAssinada">Carteira Assinada?</Label>
                        <Input type="select" id="carteiraAssinada" className="input" value={this.state.pessoa.informacoes.carteiraAssinada} onChange={this.update}>
                            <option value="">Selecione...</option>
                            <option value="true">Sim</option>
                            <option value="false">Não</option>
                        </Input>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label for="grauDeInstrucao">Grau Instrução</Label>
                        <Input
                            type="select"
                            id="grauDeInstrucao"
                            className="input"
                            value={this.state.pessoa.informacoes.grauDeInstrucao && this.state.pessoa.informacoes.grauDeInstrucao.nome}
                            onChange={this.update}
                        >
                            {grauInstrucaoComponent}
                        </Input>
                    </Col>
                    <Col>
                        <Label for="desejaVoltarEstudar">Deseja Voltar a Estudar?</Label>
                        <Input type="select" id="desejaVoltarEstudar" className="input" value={this.state.pessoa.informacoes.desejaVoltarEstudar} onChange={this.update}>
                            <option value="">Selecione...</option>
                            <option value="true">Sim</option>
                            <option value="false">Não</option>
                        </Input>
                    </Col>
                </Row>
            </Container>
        )
    }
}