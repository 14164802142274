import React from 'react'
import { Pagination, PaginationItem, PaginationLink, Fade } from 'reactstrap'

export default class ListaPessoaPagination extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    doPagination = (page) => {
        this.props.paginationHandler(page)
    }

    render() {

        const pagesLimit = this.props.pagination.total > 23 ? 23 : this.props.pagination.total - 1
        let firstPage = this.props.pagination.number - 3

        if (firstPage < 0) {
            firstPage = 0
        }

        // se enumeracao da paginacao estourar, decremente uma qtd ajuste
        if (((firstPage + 1) + pagesLimit) > this.props.pagination.total) {

            let qtdPaginaAjuste = ((firstPage + 1) + pagesLimit) - this.props.pagination.total
            firstPage -= qtdPaginaAjuste

            if (firstPage < 0) {
                firstPage = 0
            }
        }

        let isShowLastPage = false
        const paginationComponent = []

        if (this.props.pagination.total > 1) {

            for (let i = firstPage; paginationComponent.length <= pagesLimit; i++) {
                if ((i + 1) === this.props.pagination.total) {
                    isShowLastPage = true
                }
                paginationComponent.push(
                    <PaginationItem active={this.props.pagination.number === i}>
                        <PaginationLink href="#" onClick={this.doPagination.bind(this, i)}>
                            {i + 1}
                        </PaginationLink>
                    </PaginationItem>
                )
            }
        }

        return (
            <div>
                {this.props.visivel
                    ?
                    (<Fade>
                        <Pagination style={this.props.style}>

                            <PaginationItem hidden={this.props.pagination.first}>
                                <PaginationLink previous href="#" onClick={this.doPagination.bind(this, this.props.pagination.number - 1)} />
                            </PaginationItem>

                            <PaginationItem hidden={firstPage === 0}>
                                <PaginationLink href="#" onClick={this.doPagination.bind(this, 0)} >
                                    1...
                                </PaginationLink>
                            </PaginationItem>

                            {paginationComponent}

                            <PaginationItem hidden={isShowLastPage || this.props.pagination.total === 1}>
                                <PaginationLink href="#" onClick={this.doPagination.bind(this, this.props.pagination.total - 1)}>
                                    {'...'}{this.props.pagination.total}
                                </PaginationLink>
                            </PaginationItem>

                            <PaginationItem hidden={this.props.pagination.last}>
                                <PaginationLink next href="#" onClick={this.doPagination.bind(this, this.props.pagination.number + 1)} />
                            </PaginationItem>

                        </Pagination>
                    </Fade>)
                    : ''}
            </div>
        )
    }
}