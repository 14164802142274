import React from 'react'
import { Col, Row, Container, Label, Input } from 'reactstrap'

export default class InformacoesParceiro extends React.Component {

    render() {

        return (
            <Container>

                <Row>
                    <Col>
                        <Label for="publicoAlvo">Publico Alvo</Label>
                        <Input
                            id="publicoAlvo"
                            className="input"
                            data-classe="informacoesParceiro"
                            value={this.props.parceiro.informacoesParceiro.publicoAlvo}
                            onChange={this.props.updateHandler}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Label for="abrangenciaTerritorial">Abrangencia Territorial</Label>
                        <Input
                            id="abrangenciaTerritorial"
                            className="input"
                            data-classe="informacoesParceiro"
                            value={this.props.parceiro.informacoesParceiro.abrangenciaTerritorial}
                            onChange={this.props.updateHandler}
                        />
                    </Col>

                    <Col >
                        <Label for="recursosHumanosEnvolvidos">Recursos Humanos Envolvidos</Label>
                        <Input
                            id="recursosHumanosEnvolvidos"
                            className="input"
                            data-classe="informacoesParceiro"
                            value={this.props.parceiro.informacoesParceiro.recursosHumanosEnvolvidos}
                            onChange={this.props.updateHandler}
                        />
                    </Col>

                    <Col >
                        <Label for="capacidadeAtendimento">Capacidade de Atendimento</Label>
                        <Input
                            id="capacidadeAtendimento"
                            className="input"
                            data-classe="informacoesParceiro"
                            value={this.props.parceiro.informacoesParceiro.capacidadeAtendimento}
                            onChange={this.props.updateHandler}
                        />
                    </Col>
                </Row>

                <Row>

                    <Col >
                        <Label for="infoComplementar">Info. Complementar</Label>
                        <Input
                            type="textarea"
                            className="input"
                            id="infoComplementar"
                            data-classe="informacoesParceiro"
                            value={this.props.parceiro.informacoesParceiro.infoComplementar}
                            onChange={this.props.updateHandler} maxLength="255"
                        />
                    </Col>

                    <Col >
                        <Label for="avaliacaoTecnicoVisitaInstitucional">Avaliação Tecnico</Label>
                        <Input type="textarea" id="avaliacaoTecnicoVisitaInstitucional" value={this.props.parceiro.avaliacaoTecnicoVisitaInstitucional} onChange={this.props.updateHandler} />
                    </Col>

                    <Col >
                        <Label for="avaliacaoCoordenador">Avaliação Coordenador</Label>
                        <Input type="textarea" id="avaliacaoCoordenador" value={this.props.parceiro.avaliacaoCoordenador} onChange={this.props.updateHandler} />
                    </Col>
                </Row>

            </Container >
        )
    }
}