import React from 'react'
import { Container, Col, Row, Label, Input, Alert } from 'reactstrap'
import { sleep } from '../../../lib/util'

export default class CadPessoaIdentificacao extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            msg: {
                text: '',
                tipo: '',
                visivel: false
            }
        }
    }

    exibeMsgValidate() {
        this.setState({
            msg: {
                tipo: 'danger',
                text: 'Preencha o nome antes de avançar',
                visivel: true
            }
        })
    }

    limpaMsg = () => {
        this.setState({
            msg: {
                text: '',
                tipo: '',
                visivel: false
            }
        })
    }

    isValidated() {

        let parceiro = this.props.parceiro

        if (!parceiro.nome) {
            this.exibeMsgValidate()
            sleep(5000, this.limpaMsg)
            return false
        }

        return true
    }

    render() {

        return (
            <Container>

                <Alert
                    color={this.state.msg.tipo}
                    isOpen={this.state.msg.visivel}
                    children={this.state.msg.text}
                    toggle={() => this.limpaMsg()}
                />

                <Row>
                    <Col md="8">
                        <Label for="nome">Nome da Instituição ou Entidade*</Label>
                        <Input id="nome" autoComplete="off" className="input" value={this.props.parceiro.nome} onChange={this.props.updateHandler} />
                    </Col>

                    <Col>
                        <Label check style={{ marginLeft: '21px', marginTop: '40px' }}>
                            <Input id="setor1" type="checkbox" checked={this.props.parceiro.setor1} onChange={this.props.updateHandler} />
                            1º Setor
                        </Label>
                    </Col>

                    <Col>
                        <Label check style={{ marginLeft: '21px', marginTop: '40px' }}>
                            <Input id="setor2" type="checkbox" checked={this.props.parceiro.setor2} onChange={this.props.updateHandler} />
                            2º Setor
                        </Label>
                    </Col>

                    <Col>
                        <Label check style={{ marginLeft: '21px', marginTop: '40px' }}>
                            <Input id="setor3" type="checkbox" checked={this.props.parceiro.setor3} onChange={this.props.updateHandler} />
                            3º Setor
                        </Label>
                    </Col>
                </Row>

                <Row>
                    <Col md="3">
                        <Label for="cnpj">CNPJ</Label>
                        <Input id="cnpj" autoComplete="off" className="input" value={this.props.parceiro.cnpj} onChange={this.props.updateHandler} />
                    </Col>

                    <Col md="3">
                        <Label for="dataInscricaoCnpj">Data Inscrição CNPJ</Label>
                        <Input type="date" id="dataInscricaoCnpj" value={this.props.parceiro.dataInscricaoCnpj} onChange={this.props.updateHandler} />
                    </Col>

                    <Col>
                        <Label for="email">E-mail</Label>
                        <Input id="email" autoComplete="off" className="input" value={this.props.parceiro.email} onChange={this.props.updateHandler} />
                    </Col>
                </Row>

                <Row>
                    <Col md="3">
                        <Label for="cnaePrincipal">CNAE</Label>
                        <Input id="cnaePrincipal" className="input" value={this.props.parceiro.cnaePrincipal} onChange={this.props.updateHandler} />
                    </Col>

                    <Col md="3">
                        <Label for="cnaeSecundario">CNAE Secuncário</Label>
                        <Input id="cnaeSecundario" className="input" value={this.props.parceiro.cnaeSecundario} onChange={this.props.updateHandler} />
                    </Col>

                    <Col md="3">
                        <Label for="finalidadeStatutaria">Finalidade Estatutaria</Label>
                        <Input id="finalidadeStatutaria" className="input" value={this.props.parceiro.finalidadeStatutaria} onChange={this.props.updateHandler} />
                    </Col>

                    <Col md="3">
                        <Label for="origemRecursos">Origem de Recursos</Label>
                        <Input id="origemRecursos" className="input" value={this.props.parceiro.origemRecursos} onChange={this.props.updateHandler} />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Label for="objetivos">Objetivos</Label>
                        <Input type="textarea" id="objetivos" value={this.props.parceiro.objetivos} onChange={this.props.updateHandler} maxLength="255" />
                    </Col>
                    <Col>
                        <Label for="infoComplementar">Info. Complementar</Label>
                        <Input type="textarea" id="infoComplementar" value={this.props.parceiro.infoComplementar} onChange={this.props.updateHandler} maxLength="255" />
                    </Col>
                </Row>

            </Container>
        )
    }
}