import React from 'react'
import ListaParceiroItem from './ListaParceiroItem'

export default class ListaParceiro extends React.Component {


    render() {

        const parceirosComponent = this.props.parceiros.map(
            p => <ListaParceiroItem key={p.id} parceiro={p} />
        )

        return (
            <div style={{ marginTop: '15px' }}>
                {parceirosComponent}
            </div>
        )
    }
}