import React from 'react'
import { Container, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames';
import CadPessoaUploadFotoFile from './CadPessoaUploadFotoFile'
import CadPessoaUploadFotoWebCam from './CadPessoaUploadFotoWebCam'

export default class CadPessoaUploadFoto extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            activeTab: '1'
        }
    }

    toggle = tab => {
        if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
    }

    render() {
        return (
            <Container>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1') }}
                        >
                            Enviar Foto
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2') }}
                        >
                            Caputar Camera
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <CadPessoaUploadFotoFile servicePessoa={this.props.servicePessoa} pessoa={this.props.pessoa} />
                    </TabPane>
                    <TabPane tabId="2">
                        <CadPessoaUploadFotoWebCam servicePessoa={this.props.servicePessoa} pessoa={this.props.pessoa} />
                    </TabPane>
                </TabContent>
            </Container>
        )
    }
}