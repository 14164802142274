import React from 'react'
import { Col, Row, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import ReactWizard from 'react-bootstrap-wizard'
import ReactLoading from "react-loading"
import 'react-bootstrap-wizard/dist/react-wizard.css'
import PessoaService from '../../../service/PessoaService'
import ResourceService from '../../../service/ResourceService'
import { erroColor, sucessoColor, infoColor } from '../../../lib/color'
import { resetObjectProps, sleep } from '../../../lib/util'
import { withRouter } from "react-router"

import "./style.css"

import CadPessoaIdentificacao from './CadPessoaIdentificacao'
import CadPessoaEndereco from './CadPessoaEndereco'
import CadPessoaContato from './CadPessoaContato'
import CadPessoaFiliacao from './CadPessoaFiliacao'
import CadPessoaQuantitativoFamiliar from './CadPessoaQuantitativoFamiliar'
import CadPessoaProfissional from './CadPessoaProfissional'
import CadPessoaDocumento from './CadPessoaDocumento'
import CadPessoaConfirmar from './CadPessoaConfirmar'
import CadPessoaUploadFoto from './CadPessoaUploadFoto'

import { AuthContext } from '../../auth/context/AuthContext'

const StatusFinalizacaoEnum = Object.freeze({ "NAO_FINALIZADO": 1, "FINALIZANDO": 2, "FINALIZADO": 3, "ERRO_FINALIZACAO": 4 })

class CadPessoa extends React.Component {

    static contextType = AuthContext

    constructor(props) {
        super(props)
        this.state = {
            pessoa: {
                endereco: {},
                estadoCivil: {},
                sexo: {},
                etnia: {},
                telefones: [],
                quantitativoFamiliar: {},
                informacoes: {
                    profissional: {},
                    grauDeInstrucao: {}
                },
                documentos: {},
                listaPastaPessoa: []
            },
            titulo: 'Cadastro de Pessoa',
            statusFinalizacao: StatusFinalizacaoEnum.NAO_FINALIZADO,
            isModalOpen: false,
            modalTitulo: '',
            modalText: '',
            bgModalTitle: '',
            fonteModalTitle: '',
        }

        this.servicePessoa = new PessoaService()
        this.serviceResource = new ResourceService()
        this.wizardRef = null
    }

    async componentDidMount() {
        const encodedId = this.props.match.params.id
        if (encodedId) {
            setTimeout(() => {
                this.verificaPermissao('ALTERAR_PESSOA')
            })
            const id = window.atob(encodedId)
            this.carragaPessoaEdicao(id)
        } else {
            setTimeout(() => {
                this.verificaPermissao('CADASTRAR_PESSOA')
            })
        }
    }

    verificaPermissao = async (roleName) => {
        const roles = this.context.data.roles
        const isPermitido = roles
            .findIndex(role => role === roleName) > -1
        if (!isPermitido) {
            alert('O usuário logado não tem permissao para acessar essa funcionalidade')
            this.props.history.goBack()
        }
    }

    carragaPessoaEdicao = async (id) => {
        try {
            let pessoa = await this.servicePessoa.getById(id)
            this.setState({ pessoa, titulo: 'Edição de Pessoa' })
            this.redefinePessoaStateChilds(pessoa)
        } catch (error) {
            this.showModalErro(error.response ? error.response.data.message : error.message)
        }
    }

    finishButtonClick = (allStates) => {
        this.showModalConfirmacao()
    }

    confirmarCadastro = async () => {
        try {
            this.setState({ statusFinalizacao: StatusFinalizacaoEnum.FINALIZANDO })

            const fnPersist = this.props.match.params.id ? this.servicePessoa.altera : this.servicePessoa.inclui

            const pessoaIncluida = await sleep(500, fnPersist, this.state.pessoa)
            this.showModalSucesso(pessoaIncluida)

        } catch (error) {
            this.showModalErro(error.response ? error.response.data.message : error.message)
        }
    }

    showModalConfirmacao = () => {
        this.setState({
            statusFinalizacao: StatusFinalizacaoEnum.NAO_FINALIZADO,
            isModalOpen: true,
            modalTitulo: this.props.match.params.id ? 'Confirmação de edição' : 'Confirmação de cadastro',
            bgModalTitle: infoColor.background,
            fonteModalTitle: infoColor.font,
            modalText: this.props.match.params.id ? 'Confirma a edição desta pessoa?' : 'Confirma o cadastro desta pessoa?'
        })
    }

    showModalSucesso = (pessoaIncluida) => {
        this.setState({
            statusFinalizacao: StatusFinalizacaoEnum.FINALIZADO,
            isModalOpen: true,
            modalTitulo: this.props.match.params.id ? 'Pessoa Editada com Sucesso' : 'Pessoa Cadastrada com Sucesso',
            bgModalTitle: sucessoColor.background,
            fonteModalTitle: sucessoColor.font,
            pessoa: pessoaIncluida,
        })
    }

    showModalErro = (error) => {
        this.setState({
            statusFinalizacao: StatusFinalizacaoEnum.ERRO_FINALIZACAO,
            isModalOpen: true,
            modalTitulo: this.props.match.params.id ? 'Erro ao confirmar a edição' : 'Erro ao confirmar o cadastro',
            bgModalTitle: erroColor.background,
            fonteModalTitle: erroColor.font,
            modalText: error,
        })
    }

    closeModal = () => {

        this.setState({ isModalOpen: false })

        if (this.state.statusFinalizacao === StatusFinalizacaoEnum.FINALIZADO) {

            if (this.props.match.params.id) {
                this.props.history.push('/pessoa')
                return
            }

            let pessoa = this.state.pessoa

            resetObjectProps(pessoa)

            this.setState({
                pessoa,
                statusFinalizacao: StatusFinalizacaoEnum.NAO_FINALIZADO
            })

            this.redefinePessoaStateChilds(pessoa)

            // reseta o wizard para o step 1
            this.wizardRef.refreshAnimation(0)
            this.wizardRef.setState({
                currentStep: 0,
                previousButton: false,
                finishButton: false,
                nextButton: true,
            })
        }
    }

    // roda e busca subcomps do wizard para resetar o stado de pessoa
    redefinePessoaStateChilds = (pessoa) => {

        let refs = this.wizardRef.refs
        for (const comp in refs) {
            if (refs.hasOwnProperty(comp)) {
                const component = refs[comp]
                if (component.state && component.state.pessoa) {
                    component.setState({ pessoa })
                }
            }
        }
    }

    render() {

        let bodyModal
        switch (this.state.statusFinalizacao) {
            case StatusFinalizacaoEnum.NAO_FINALIZADO:
                bodyModal = this.state.modalText
                break
            case StatusFinalizacaoEnum.FINALIZANDO:
                bodyModal = (
                    <Row>
                        <Col xs={12} md={3} className="mr-auto ml-auto">
                            <ReactLoading type="spokes" color="#0069D9" />
                        </Col>
                    </Row>
                )
                break
            case StatusFinalizacaoEnum.FINALIZADO:
                bodyModal = <CadPessoaUploadFoto servicePessoa={this.servicePessoa} pessoa={this.state.pessoa} />
                break
            case StatusFinalizacaoEnum.ERRO_FINALIZACAO:
                bodyModal = this.state.modalText
                break
            default:
        }

        return (
            <Container fluid style={{ marginTop: "15px" }} >
                <Row>
                    <Col xs={12} md={10} className="mr-auto ml-auto">
                        <ReactWizard
                            steps={[
                                {
                                    stepName: "Identificação",
                                    component: CadPessoaIdentificacao,
                                    stepProps: { pessoa: this.state.pessoa, serviceResource: this.serviceResource }
                                },
                                {
                                    stepName: "Endereço",
                                    component: CadPessoaEndereco,
                                    stepProps: { pessoa: this.state.pessoa }
                                },
                                {
                                    stepName: "Contato",
                                    component: CadPessoaContato,
                                    stepProps: { pessoa: this.state.pessoa }
                                },
                                {
                                    stepName: "Filiação",
                                    component: CadPessoaFiliacao,
                                    stepProps: { pessoa: this.state.pessoa }
                                },
                                {
                                    stepName: "Quantitativo Familiar",
                                    component: CadPessoaQuantitativoFamiliar,
                                    stepProps: { pessoa: this.state.pessoa }
                                },
                                {
                                    stepName: "Profissional",
                                    component: CadPessoaProfissional,
                                    stepProps: { pessoa: this.state.pessoa, serviceResource: this.serviceResource }
                                },
                                {
                                    stepName: "Documentos que Possui",
                                    component: CadPessoaDocumento,
                                    stepProps: { pessoa: this.state.pessoa }
                                },
                                {
                                    stepName: "Finalizar",
                                    component: CadPessoaConfirmar,
                                    stepProps: { pessoa: this.state.pessoa }
                                }
                            ]}
                            navSteps
                            title={this.state.titulo}
                            headerTextCenter
                            validate
                            color="bosta"
                            previousButtonText="Voltar"
                            nextButtonText="Avançar"
                            finishButtonText={this.props.match.params.id ? 'Confirmar Edição' : "Confirmar Cadastro"}
                            finishButtonClasses="btn-info"
                            finishButtonClick={this.finishButtonClick}
                            ref={element => this.wizardRef = element}
                        />
                    </Col>
                </Row>

                <Modal isOpen={this.state.isModalOpen} backdrop={true} keyboard={false}>
                    <ModalHeader style={{ background: this.state.bgModalTitle }}>
                        <div>
                            <h4 style={{ color: this.state.fonteModalTitle }}>{this.state.modalTitulo}</h4>
                        </div>
                    </ModalHeader>
                    <ModalBody>
                        {bodyModal}
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.state.statusFinalizacao === StatusFinalizacaoEnum.NAO_FINALIZADO &&
                            < Button color="primary" onClick={this.confirmarCadastro}>Confirmar</Button>
                        }
                        <Button
                            color="secondary"
                            onClick={this.closeModal}
                            disabled={this.state.statusFinalizacao === StatusFinalizacaoEnum.FINALIZANDO}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container >
        );
    }
}

export default withRouter(CadPessoa)