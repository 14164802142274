import React from 'react'
import { FaUserPlus } from 'react-icons/fa'
import {
    Container,
    Row,
    Col,
    Input,
    Button,
    Alert,
} from 'reactstrap'
import UsuarioService from '../../service/UsuarioService'
import ResourceService from '../../service/ResourceService'
import DialogCadastroUsuario from './DialogCadastroUsuario'
import UsuarioLista from './UsuarioLista'
import { GerenciarUsuarioContext } from './context/GerenciarUsuarioContext'

const StatusEnum = Object.freeze({ "AMBOS": '0', "ATIVO": '1', "INATIVO": '2' })

export default class GerenciarUsuario extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            usuarios: [],
            status: StatusEnum.ATIVO,
            isOpenCadastro: false,
            msg: {
                text: '',
                tipo: '',
                visivel: false
            }
        }
        this.permissoes = []
        this.staticUsuarios = []
        this.usuarioService = new UsuarioService()
        this.resourceService = new ResourceService()
    }

    componentDidMount() {
        this.loadUsers()
    }

    loadUsers = async () => {
        try {
            this.permissoes = await this.resourceService.permissoes()
            this.staticUsuarios = await this.usuarioService.lista()
            this.setState({ usuarios: this.staticUsuarios }, this.filtrarByAtividade)
        } catch (error) {
            this.setState({
                msg: {
                    tipo: 'danger',
                    text: error.response ? error.response.data.error : error.message,
                    visivel: true
                }
            })
        }
    }

    update = ({ target }) => {
        if (target.id === 'status') {
            this.setState({ status: target.value }, this.filtrarByAtividade)
        }
    }

    filtrarByAtividade = () => {

        const { status } = this.state

        const usuarios = this.staticUsuarios.filter(u => {

            switch (status) {
                case StatusEnum.ATIVO:
                    return u.ativo
                case StatusEnum.INATIVO:
                    return !u.ativo
                default:
                    return true
            }
        })

        this.setState({ usuarios })
    }

    afterSuccessCadastroHandler = async () => {
        this.loadUsers()
    }

    render() {
        return (
            <GerenciarUsuarioContext.Provider
                value={{
                    usuarios: this.state.usuarios,
                    usuarioService: this.usuarioService,
                    permissoes: this.permissoes
                }}
            >

                <Container style={{ marginTop: '50px' }}>
                    <Row style={{ marginBottom: '20px' }}>
                        <Col md="3">
                            <Input type="select" id="status" value={this.state.status} onChange={this.update}>
                                <option key={StatusEnum.ATIVO} value={StatusEnum.ATIVO}>Ativos</option>
                                <option key={StatusEnum.INATIVO} value={StatusEnum.INATIVO}>Inativos</option>
                                <option key={StatusEnum.AMBOS} value={StatusEnum.AMBOS}>Ambos</option>
                            </Input>
                        </Col>
                        <Col>
                            <Button
                                color="success"
                                size="sm"
                                style={{ float: 'left', marginTop: '3px' }}
                                onClick={() => this.setState({ isOpenCadastro: true })}
                            >
                                {'Novo Usuário '}
                                <FaUserPlus style={{ marginBottom: '3px', marginLeft: '5px' }} />
                            </Button>
                        </Col>
                    </Row>

                    <Alert
                        color={this.state.msg.tipo}
                        isOpen={this.state.msg.visivel}
                        children={this.state.msg.text}
                        toggle={() => this.setState({ msg: { visivel: false } })}
                    />

                    <UsuarioLista />

                    <DialogCadastroUsuario
                        isOpen={this.state.isOpenCadastro}
                        toogle={() => this.setState({ isOpenCadastro: false })}
                        afterSuccessCadastro={this.afterSuccessCadastroHandler}
                    />
                </Container>

            </GerenciarUsuarioContext.Provider>
        )
    }
}