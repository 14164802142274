import React from 'react'
import { Col, Row, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import ReactWizard from 'react-bootstrap-wizard'
import ReactLoading from "react-loading";
import 'react-bootstrap-wizard/dist/react-wizard.css'
import ParceiroService from '../../../service/ParceiroService'
import { erroColor, sucessoColor, infoColor } from '../../../lib/color'
import { resetObjectProps, sleep } from '../../../lib/util'
import { withRouter } from "react-router"

import IdentificacaoParceiro from './IdentificacaoParceiro'
import EnderecoParceiro from './EnderecoParceiro'
import ContatoParceiro from './ContatoParceiro'
import ContatoTecnicoParceiro from './ContatoTecnicoParceiro'
import SinteseServicoParceiro from './SinteseServicoParceiro'
import InformacoesParceiro from './InformacoesParceiro'
import ConfirmarParceiro from './ConfirmarParceiro'

import { AuthContext } from '../../auth/context/AuthContext'
import { CadParceiroContext } from './context/CadParceiroContext';

const StatusFinalizacaoEnum = Object.freeze({ "NAO_FINALIZADO": 1, "FINALIZANDO": 2, "FINALIZADO": 3, "ERRO_FINALIZACAO": 4 })

class CadParceiro extends React.Component {

    static contextType = AuthContext

    constructor(props) {
        super(props)
        this.state = {
            parceiro: {
                endereco: {},
                telefones: [],
                contatoTecnico: {
                    telefone: {}
                },
                informacoesParceiro: {},
                representanteLegal: {},
                listaSinteseServicoParceiro: []
            },
            titulo: 'Cadastro de Parceria Institucional',
            statusFinalizacao: StatusFinalizacaoEnum.NAO_FINALIZADO,
            isModalOpen: false,
            modalTitulo: '',
            modalTextError: '',
            bgModalTitle: '',
            fonteModalTitle: '',
            roles: {
                isPermiteCadastraServicoParceiro: false,
                isPermiteExcluiServicoParceiro: false
            }
        }
        this.serviceParceiro = new ParceiroService()
        this.wizardRef = null
    }

    async componentDidMount() {
        const ecodedId = this.props.match.params.id
        if (ecodedId) {
            setTimeout(() => {
                this.verificaPermissao('ALTERAR_PARCEIRO')
            })
            const id = window.atob(ecodedId)
            this.carragaParceiroEdicao(id)
        } else {
            setTimeout(() => {
                this.verificaPermissao('CADASTRAR_PARCEIRO')
            })
        }
        this.carregaPermissoes()
    }

    carregaPermissoes = () => {
        const isPermiteCadastraServicoParceiro = this.hasPermissao('CADASTRAR_SERVICO')
        const isPermiteExcluiServicoParceiro = this.hasPermissao('EXCLUIR_SERVICO')
        this.setState({
            roles: {
                isPermiteCadastraServicoParceiro,
                isPermiteExcluiServicoParceiro
            }
        })
    }

    verificaPermissao = async (roleName) => {
        if (!this.hasPermissao(roleName)) {
            alert('O usuário logado não tem permissao para acessar essa funcionalidade')
            this.props.history.goBack()
        }
    }

    hasPermissao = (roleName) => {
        const roles = this.context.data.roles
        const isPermitido = roles
            .findIndex(role => role === roleName) > -1
        return isPermitido
    }

    carragaParceiroEdicao = async (id) => {
        try {
            let parceiro = await this.serviceParceiro.getById(id)
            this.setState({ parceiro, titulo: 'Edição de Parceria Institucional' })
        } catch (error) {
            this.showModalErro(error.response.data.message)
        }
    }

    update = (event) => {

        let parceiro = this.state.parceiro

        const classe = event.target.getAttribute('data-classe')
        const subclasse = event.target.getAttribute('data-subclasse')
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value

        if (subclasse) {
            parceiro[`${classe}`][`${subclasse}`][`${event.target.id}`] = value
        }
        else if (classe) {
            parceiro[`${classe}`][`${event.target.id}`] = value
        }
        else {
            parceiro[`${event.target.id}`] = value
        }

        this.setState({ parceiro })
    }

    finishButtonClick = (allStates) => {
        this.showModalConfirmacao()
    }

    confirmarCadastro = async () => {

        try {
            this.setState({ statusFinalizacao: StatusFinalizacaoEnum.FINALIZANDO })

            const fnPersist = this.props.match.params.id ? this.serviceParceiro.altera : this.serviceParceiro.inclui

            await sleep(500, fnPersist, this.state.parceiro)
            this.showModalSucesso()

        } catch (error) {
            this.showModalErro(error.response ? error.response.data.message : error.message)
        }
    }

    showModalConfirmacao = () => {
        this.setState({
            statusFinalizacao: StatusFinalizacaoEnum.NAO_FINALIZADO,
            isModalOpen: true,
            modalTitulo: this.props.match.params.id ? 'Confirmação de edição' : 'Confirmação de cadastro',
            bgModalTitle: infoColor.background,
            fonteModalTitle: infoColor.font
        })
    }

    showModalSucesso = () => {
        const parceiro = this.state.parceiro
        resetObjectProps(parceiro)
        this.setState({
            statusFinalizacao: StatusFinalizacaoEnum.FINALIZADO,
            isModalOpen: true,
            modalTitulo: this.props.match.params.id ? 'Parceiro Editado com Sucesso' : 'Parceiro Cadastrado com Sucesso',
            bgModalTitle: sucessoColor.background,
            fonteModalTitle: sucessoColor.font,
            parceiro,
        })
    }

    showModalErro = (error) => {
        this.setState({
            statusFinalizacao: StatusFinalizacaoEnum.ERRO_FINALIZACAO,
            isModalOpen: true,
            modalTitulo: this.props.match.params.id ? 'Erro ao confirmar a edição' : 'Erro ao confirmar o cadastro',
            bgModalTitle: erroColor.background,
            fonteModalTitle: erroColor.font,
            modalTextError: error,
        })
    }

    closeModal = () => {

        this.setState({ isModalOpen: false })

        if (this.state.statusFinalizacao === StatusFinalizacaoEnum.FINALIZADO) {

            if (this.props.match.params.id) {
                this.props.history.push('/parceiro')
                return
            }

            let parceiro = this.state.parceiro

            resetObjectProps(parceiro)

            this.setState({
                parceiro,
                statusFinalizacao: StatusFinalizacaoEnum.NAO_FINALIZADO
            })

            // reseta o wizard para o step 1
            this.wizardRef.refreshAnimation(0)
            this.wizardRef.setState({
                currentStep: 0,
                previousButton: false,
                finishButton: false,
                nextButton: true,
            })
        }
    }

    render() {

        let bodyModal
        switch (this.state.statusFinalizacao) {
            case StatusFinalizacaoEnum.NAO_FINALIZADO:
                bodyModal = this.props.match.params.id ? 'Confirma a edição deste parceiro?' : 'Confirma o cadastro deste parceiro?'
                break
            case StatusFinalizacaoEnum.FINALIZANDO:
                bodyModal = (
                    <Row>
                        <Col xs={12} md={3} className="mr-auto ml-auto">
                            <ReactLoading type="spokes" color="#0069D9" />
                        </Col>
                    </Row>
                )
                break
            case StatusFinalizacaoEnum.FINALIZADO:
                bodyModal = this.props.match.params.id ? 'Parceiro Editado com Sucesso' : 'Parceiro Cadastrado com Sucesso'
                break
            case StatusFinalizacaoEnum.ERRO_FINALIZACAO:
                bodyModal = this.state.modalTextError
                break
            default:
        }

        return (
            <CadParceiroContext.Provider
                value={{
                    isPermiteCadastraServicoParceiro: this.state.roles.isPermiteCadastraServicoParceiro,
                    isPermiteExcluiServicoParceiro: this.state.roles.isPermiteExcluiServicoParceiro
                }}
            >
                <Container fluid style={{ marginTop: "15px" }}>
                    <Row>
                        <Col xs={12} md={10} className="mr-auto ml-auto">
                            <ReactWizard
                                steps={[
                                    {
                                        stepName: "Identificação",
                                        component: IdentificacaoParceiro,
                                        stepProps: { updateHandler: this.update, parceiro: this.state.parceiro }
                                    },
                                    {
                                        stepName: "Endereço",
                                        component: EnderecoParceiro,
                                        stepProps: { updateHandler: this.update, parceiro: this.state.parceiro }
                                    },
                                    {
                                        stepName: "Contato",
                                        component: ContatoParceiro,
                                        stepProps: { parceiro: this.state.parceiro }
                                    },
                                    {
                                        stepName: "Contato Técnico",
                                        component: ContatoTecnicoParceiro,
                                        stepProps: { updateHandler: this.update, parceiro: this.state.parceiro }
                                    },
                                    {
                                        stepName: "Sintese dos Serviços",
                                        component: SinteseServicoParceiro,
                                        stepProps: { parceiro: this.state.parceiro }
                                    },
                                    {
                                        stepName: "Informações",
                                        component: InformacoesParceiro,
                                        stepProps: { updateHandler: this.update, parceiro: this.state.parceiro }
                                    },
                                    {
                                        stepName: "Finalizar",
                                        component: ConfirmarParceiro,
                                        stepProps: { updateHandler: this.update, parceiro: this.state.parceiro }
                                    },
                                ]}
                                navSteps
                                title={this.state.titulo}
                                headerTextCenter
                                validate
                                color="bosta"
                                previousButtonText="Voltar"
                                nextButtonText="Avançar"
                                finishButtonText={this.props.match.params.id ? 'Confirmar Edição' : "Confirmar Cadastro"}
                                finishButtonClasses="btn-info"
                                finishButtonClick={this.finishButtonClick}
                                ref={element => this.wizardRef = element}
                            />
                        </Col>
                    </Row>

                    <Modal isOpen={this.state.isModalOpen} backdrop={true} keyboard={false}>
                        <ModalHeader style={{ background: this.state.bgModalTitle }}>
                            <div>
                                <h4 style={{ color: this.state.fonteModalTitle }}>{this.state.modalTitulo}</h4>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            {bodyModal}
                        </ModalBody>
                        <ModalFooter>
                            {
                                this.state.statusFinalizacao === StatusFinalizacaoEnum.NAO_FINALIZADO &&
                                < Button color="primary" onClick={this.confirmarCadastro}>Confirmar</Button>
                            }
                            <Button
                                color="secondary"
                                onClick={this.closeModal}
                                disabled={this.state.statusFinalizacao === StatusFinalizacaoEnum.FINALIZANDO}>
                                Fechar
                            </Button>
                        </ModalFooter>
                    </Modal>
                </Container >
            </CadParceiroContext.Provider>
        );
    }
}

export default withRouter(CadParceiro)