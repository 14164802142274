import { maskBr, validateBr } from 'js-brasil'

function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function currentTimeStamp() {
    return new Date().getTime()
}

/**
 * Convert a base64 string in a Blob according to the data and contentType.
 * 
 * @param b64Data {String} Pure base64 string without contentType
 * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
 * @param sliceSize {Int} SliceSize to process the byteCharacters
 * @return Blob
 */
function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}


function resetObjectProps(obj) {

    for (const prop in obj) {

        if (obj.hasOwnProperty(prop)) {

            switch (typeof (obj[prop])) {
                case 'object':
                    if (Array.isArray(obj[prop])) {
                        obj[prop] = []
                    } else {
                        resetObjectProps(obj[prop])
                    }
                    break
                default:
                    obj[prop] = ''
            }
        }
    }
}

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function sleep(mili, fn, ...args) {
    await timeout(mili);
    return fn(...args);
}

const formatter = {

    cpf(value, onlyValid = true) {
        if (onlyValid) {
            if (value && validateBr.cpf(value)) {
                return maskBr.cpf(value)
            } else {
                return value
            }
        }
        return maskBr.cpf(value)
    },

    cnpj(value, onlyValid = true) {
        if (onlyValid) {
            if (value && validateBr.cnpj(value)) {
                return maskBr.cnpj(value)
            } else {
                return value
            }
        }
        return maskBr.cnpj(value)
    },

    telefone(ddd, number, onlyValid = true) {

        const value = `${ddd}${number}`

        if(value === '00') {
            return '';
        } 

        if (onlyValid) {
            if (value && validateBr.telefone(value)) {
                return maskBr.telefone(value)
            } else {
                return value
            }
        }
        return maskBr.telefone(value)
    },

    cep(value, onlyValid = true) {

        if (onlyValid) {
            if (value && validateBr.cep(value)) {
                return maskBr.cep(value)
            } else {
                return value
            }
        }
        return maskBr.cep(value)
    },

    data(value) {
        if (value) {
            var l10nBR = new Intl.DateTimeFormat("pt-BR")
            return l10nBR.format(new Date(`${value} 23:59:59`))
        }
        return value
    },
}

function somenteNumeros(value) {
    return value.replace(/[^\d]+/g,'')
}

export {
    isObjectEmpty,
    resetObjectProps,
    currentTimeStamp,
    b64toBlob,
    sleep,
    formatter,
    somenteNumeros
}