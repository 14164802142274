import API from '../lib/api'

export default class ResourceService {

    constructor() {
        this.baseURL = 'resources'
    }

    async fieldsPesquisaPessoa() {
        const resp = await API.get(`${this.baseURL}/fieldspesquisa/pessoa`)

        return resp.data
    }

    async fieldsPesquisaParceiro() {
        const resp = await API.get(`${this.baseURL}/fieldspesquisa/parceiro`)

        return resp.data
    }

    async compareConstraints(tipo) {
        const resp = await API.get(`${this.baseURL}/regracomparacao/${tipo}`)

        return resp.data
    }

    async estadoCivil() {
        const resp = await API.get(`${this.baseURL}/estadocivil`)

        return resp.data
    }

    async sexo() {
        const resp = await API.get(`${this.baseURL}/sexo`)

        return resp.data
    }

    async etnia() {
        const resp = await API.get(`${this.baseURL}/etnia`)

        return resp.data
    }

    async escolaridade() {
        const resp = await API.get(`${this.baseURL}/escolaridade`)
 
        return resp.data
    }

    async permissoes() {
        const resp = await API.get(`${this.baseURL}/permissoes`)
 
        return resp.data
    }
}