// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;
}

body {
	font-family: Helvetica, Arial, sans-serif;
	color: #222831;
}

h2 {
	font-weight: normal;
	text-align: left;
}

span {
    font-weight: bold;
}

.span-item {
    margin-left: 30px;
}

.flex-container {
	margin: 0 20px 20px 20px;
	/* font-size: 2em; */
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.item {
	width: calc(100% - 40px);
	margin: 0 auto;
	padding: 40px;
	border-radius: 5px;
	box-shadow: 0 2px 8px 2px rgba(20,23,28,0.15);
	
}

.item p {
	margin-bottom: 10px;
	font-size: 1.2em;
}

.item:nth-child(2n) {
	margin-left: 20px;
}

.flex-container p {
	font-weight: bold;
	margin-bottom: 7px;
}

.flex-container p span, .flex-container p sup {
	font-weight: normal;
}

.float p:first-child {
	float: left;
	margin-bottom: 0;
}

.float p:last-child {
	float: right;
	margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/pessoa/viewpessoa/style.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT,UAAU;CACV,SAAS;CACT,sBAAsB;AACvB;;AAEA;CACC,yCAAyC;CACzC,cAAc;AACf;;AAEA;CACC,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;CACC,wBAAwB;CACxB,oBAAoB;CACpB,aAAa;CACb,eAAe;CACf,uBAAuB;AACxB;;AAEA;CACC,wBAAwB;CACxB,cAAc;CACd,aAAa;CACb,kBAAkB;CAClB,6CAA6C;;AAE9C;;AAEA;CACC,mBAAmB;CACnB,gBAAgB;AACjB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,iBAAiB;CACjB,kBAAkB;AACnB;;AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,WAAW;CACX,gBAAgB;AACjB;;AAEA;CACC,YAAY;CACZ,gBAAgB;AACjB","sourcesContent":["* {\n\tmargin: 0;\n\tpadding: 0;\n\tborder: 0;\n\tbox-sizing: border-box;\n}\n\nbody {\n\tfont-family: Helvetica, Arial, sans-serif;\n\tcolor: #222831;\n}\n\nh2 {\n\tfont-weight: normal;\n\ttext-align: left;\n}\n\nspan {\n    font-weight: bold;\n}\n\n.span-item {\n    margin-left: 30px;\n}\n\n.flex-container {\n\tmargin: 0 20px 20px 20px;\n\t/* font-size: 2em; */\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\talign-items: flex-start;\n}\n\n.item {\n\twidth: calc(100% - 40px);\n\tmargin: 0 auto;\n\tpadding: 40px;\n\tborder-radius: 5px;\n\tbox-shadow: 0 2px 8px 2px rgba(20,23,28,0.15);\n\t\n}\n\n.item p {\n\tmargin-bottom: 10px;\n\tfont-size: 1.2em;\n}\n\n.item:nth-child(2n) {\n\tmargin-left: 20px;\n}\n\n.flex-container p {\n\tfont-weight: bold;\n\tmargin-bottom: 7px;\n}\n\n.flex-container p span, .flex-container p sup {\n\tfont-weight: normal;\n}\n\n.float p:first-child {\n\tfloat: left;\n\tmargin-bottom: 0;\n}\n\n.float p:last-child {\n\tfloat: right;\n\tmargin-bottom: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
