import React from 'react'
import { Container, Col, Row, Label, Input, Alert } from 'reactstrap'
import { sleep } from '../../../lib/util'
import { mascaraCPF, mascaraRG } from '../../../lib/mascara'

export default class CadPessoaIdentificacao extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            pessoa: props.pessoa,
            msg: {
                text: '',
                tipo: '',
                visivel: false
            },
            itensEstadoCivil: [],
            itensSexo: [],
            itensEtnia: [],
        }
    }

    async componentDidMount() {

        try {

            const itensEstadoCivil = await this.props.serviceResource.estadoCivil()
            const itensSexo = await this.props.serviceResource.sexo()
            const itensEtnia = await this.props.serviceResource.etnia()

            this.setState({
                itensEstadoCivil,
                itensSexo,
                itensEtnia,
            })

        } catch (error) {
            alert(error.response.data.error)
        }
    }

    update = (e) => {

        let pessoa = this.state.pessoa

        switch (e.target.id) {
            case 'rg':
                pessoa.documentos[`${e.target.id}`] = mascaraRG(e.target.value)
                break
            case 'cpf':
                pessoa.documentos[`${e.target.id}`] = mascaraCPF(e.target.value)
                break
            case 'estadoCivil':
            case 'sexo':
            case 'etnia':
                if (!pessoa[`${e.target.id}`]) {
                    pessoa[`${e.target.id}`] = {}
                }
                pessoa[`${e.target.id}`].nome = e.target.value
                pessoa[`${e.target.id}`].classe = e.target.options[e.target.selectedIndex].getAttribute('data-classe')
                break
            default:
                pessoa[`${e.target.id}`] = e.target.value
        }

        this.setState({ pessoa })
    }

    exibeMsgValidate() {
        this.setState({
            msg: {
                tipo: 'danger',
                text: 'Preencha o nome antes de avançar',
                visivel: true
            }
        })
    }

    limpaMsg = () => {
        this.setState({
            msg: {
                text: '',
                tipo: '',
                visivel: false
            }
        })
    }

    isValidated() {

        let pessoa = this.state.pessoa

        if (!pessoa.nome) {
            this.exibeMsgValidate()
            sleep(5000, this.limpaMsg)
            return false
        }

        return true
    }

    render() {

        const estadoCivilComponent = this.state.itensEstadoCivil.map(i => <option key={i.nome} value={i.nome} data-classe={i.classe}>{i.descricao}</option>)
        estadoCivilComponent.splice(0, 0, <option key={estadoCivilComponent} value="">Selecione...</option>)

        const sexoComponent = this.state.itensSexo.map(i => <option key={i.nome} value={i.nome} data-classe={i.classe}>{i.descricao}</option>)
        sexoComponent.splice(0, 0, <option key={sexoComponent} value="">Selecione...</option>)

        const etniaComponent = this.state.itensEtnia.map(i => <option key={i.nome} value={i.nome} data-classe={i.classe}>{i.descricao}</option>)
        etniaComponent.splice(0, 0, <option key={etniaComponent} value="">Selecione...</option>)

        return (
            <Container>

                <Alert
                    color={this.state.msg.tipo}
                    isOpen={this.state.msg.visivel}
                    children={this.state.msg.text}
                    toggle={() => this.limpaMsg()}
                />

                <Row>
                    <Col>
                        <Label for="nome">Nome*</Label>
                        <Input id="nome" autoComplete="off" className="input" value={this.state.pessoa.nome} onChange={this.update} />
                    </Col>
                </Row>

                <Row>
                    <Col md="3">
                        <Label for="rg">RG</Label>
                        <Input id="rg" autoComplete="off" className="input" value={this.state.pessoa.documentos.rg} onChange={this.update} />
                    </Col>

                    <Col md="3">
                        <Label for="cpf">CPF</Label>
                        <Input id="cpf" autoComplete="off" className="input" value={this.state.pessoa.documentos.cpf} onChange={this.update} />
                    </Col>

                    <Col md="3">
                        <Label>Data Nascimento</Label>
                        <Input type="date" id="dataNascimento" className="input" value={this.state.pessoa.dataNascimento} onChange={this.update} />
                    </Col>

                    <Col md="3">
                        <Label>Estado Civil</Label>
                        <Input
                            type="select"
                            id="estadoCivil"
                            className="input"
                            value={this.state.pessoa.estadoCivil && this.state.pessoa.estadoCivil.nome}
                            onChange={this.update}
                        >
                            {estadoCivilComponent}
                        </Input>
                    </Col>
                </Row>

                <Row >
                    <Col>
                        <Label for="email">E-mail</Label>
                        <Input type="email" id="email" className="input" value={this.state.pessoa.email} onChange={this.update} />
                    </Col>

                    <Col>
                        <Label for="sexo">Sexo</Label>
                        <Input
                            type="select"
                            id="sexo"
                            className="input"
                            value={this.state.pessoa.sexo && this.state.pessoa.sexo.nome}
                            onChange={this.update}
                        >
                            {sexoComponent}
                        </Input>
                    </Col>

                    <Col>
                        <Label for="etnia">Etnia</Label>
                        <Input
                            type="select"
                            id="etnia"
                            className="input"
                            value={this.state.pessoa.etnia && this.state.pessoa.etnia.nome}
                            onChange={this.update}
                        >
                            {etniaComponent}
                        </Input>
                    </Col>
                </Row>
            </Container>
        )
    }
}