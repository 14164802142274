import React from 'react'
import { Container, Input, Label, Row, Col } from 'reactstrap'

export default class ContatoTecnicoParceiro extends React.Component {

    render() {
        return (
            <Container>
                <Row>
                    <Col md="2">
                        <Label for="ddd">DDD</Label>
                        <Input
                            data-classe="contatoTecnico"
                            data-subclasse="telefone"
                            id="ddd"
                            maxLength="3"
                            value={this.props.parceiro.contatoTecnico.telefone.ddd || ''}
                            onChange={this.props.updateHandler}
                            />
                    </Col>
                    <Col md="4">
                        <Label for="numero">Telefone</Label>
                        <Input
                            data-classe="contatoTecnico"
                            data-subclasse="telefone"
                            id="numero"
                            value={this.props.parceiro.contatoTecnico.telefone.numero || ''}
                            onChange={this.props.updateHandler}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '25px' }}>
                    <Col md="8">
                        <Label for="nome">Nome</Label>
                        <Input data-classe="contatoTecnico" id="nome" value={this.props.parceiro.contatoTecnico.nome} onChange={this.props.updateHandler} />
                    </Col>
                    <Col>
                        <Label for="formacao">Formação</Label>
                        <Input data-classe="contatoTecnico" id="formacao" value={this.props.parceiro.contatoTecnico.formacao} onChange={this.props.updateHandler} />
                    </Col>
                </Row>
            </Container>
        )
    }
}