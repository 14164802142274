import API from '../lib/api'

export default class ParceiroService {

    constructor() {
        this.baseURL = 'parceiro'
        this.inclui = this.inclui.bind(this)
        this.altera = this.altera.bind(this)
        this.getById = this.getById.bind(this)
        this.lista = this.lista.bind(this)
    }

    async inclui(parceiro) {

        const resp = await API.post(this.baseURL, parceiro)

        return resp.data
    }

    async altera(parceiro) {

        const resp = await API.put(this.baseURL, parceiro)

        return resp.data
    }


    async getById(id) {

        const resp = await API.get(`${this.baseURL}/${id}`)

        return resp.data
    }

    async lista(filtro) {

        const resp = await API.post(`${this.baseURL}/pesquisa`, filtro)

        return resp.data
    }

    async uploadArquivo(idParceiro, file) {

        const formData = new FormData()

        formData.append('id', idParceiro)
        formData.append("file", file, file.name)

        const resp = await API.post(`${this.baseURL}/arquivo`, formData)

        return resp.data
    }

    async downloadArquivo(idParceiro, idArquivo) {

        const resp = await API.get(`${this.baseURL}/arquivo/${idParceiro}/${idArquivo}`, {
            responseType: 'blob'
        })

        return resp.data
    }

    async deleteArquivo(idParceiro, idArquivo) {
        API.delete(`${this.baseURL}/arquivo/${idParceiro}/${idArquivo}`)
    }
}